import { AxiosInstance } from "../axios/AxiosProvider";
import { Auth0Api } from "../API/auth0-api";
import { setDateOF15 } from "utils/utils";


export const StudentAPIS = {



  async createStudent(params) {
    
    let api_params = [];
    api_params["email"] = params.email;
    api_params["password"] = params.password;
    api_params["username"] = params.username;
    api_params["firstname"] = params.firstname;
    api_params["lastname"] = params.lastname;
    api_params["user_metadata"] = {
      role: "student",
      firstname: params.firstname,
      lastname: params.lastname,
      email: params.email,
      username: params.username,
    };

    try {
      let response = await Auth0Api.getAuthenticationFromAuth0(api_params);
      
      if (response?.Id) {
        
        return this.registerUser(response?.Id);
      } else {
        
        return response;
      }
    } catch (e) {
      
      return e;
    }
  },



  async fetchAuthUserDetailsFromAuth0(params) {
    try {
      

      let response = await Auth0Api.getUserInfo(params);
      
      if (response?.sub) {
        if (response.email_verified)
          return this.loginAtServer(response?.sub);
        else
          return response
      } else return response;
    } catch (e) {
      
      return e;
    }
  },

  async registerUser(userId) {
    
    try {
      const response = await AxiosInstance.post("/user/register", {
        user_id: `auth0|${userId}`,
      });
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async saveEducationData(params, token) {
    
    try {
      const response = await AxiosInstance.post(
        "/user_educations",
        {
          headers: {
            "x-access-token": token,
          },
          ...params,
        },
        { headers: { "x-access-token": token } }
      );
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async updateEducationData(id, params, token) {
    
    try {
      const response = await AxiosInstance.post(
        `/user_educations/${id}`,
        {
          headers: {
            "x-access-token": token,
          },
          ...params,
        },
        { headers: { "x-access-token": token } }
      );
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async loginAtServer(userId) {
    

    try {
      const response = await AxiosInstance.post("/user/login", {
        user_id: userId,
      });
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async fetchEducationInfo(token, username) {
    let endpoint = username ? `/user_educations/${username}` : `/user_educations`;

    try {
      const response = await AxiosInstance.get(endpoint, {
        headers: { "x-access-token": token },
      });
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async deleteEducation(id, token) {
    try {
      const response = await AxiosInstance.delete(`/user_educations/${id}`, {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },
  
 

  async deleteWork(id, token) {
    try {
      const response = await AxiosInstance.delete(`/user_works/${id}`, {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },


  async fetchWorkInfo(token,username) {
    let endpoint = username ? `/user_works/${username}` : `/user_works`;

    try {
      const response = await AxiosInstance.get(endpoint, {
        headers: { "x-access-token": token },
      });
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async fetchCertificationInfo(token,username) {
    let endpoint = username ? `/user_certificates/${username}` : `/user_certificates`;
   
    try {
      const response = await AxiosInstance.get(endpoint, {
        headers: { "x-access-token": token },
      });
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async fetchAchievementInfo(token,username) {
    let endpoint = username ? `/user_achievements/${username}` : `/user_achievements`;

    try {
      const response = await AxiosInstance.get(endpoint, {
        headers: { "x-access-token": token },
      });
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async updateStudentDetails(params, location, token) {
    let newdate = setDateOF15(params?.yearOfBirth)
    
    try {
      const formData = new FormData();
      formData.append("image", params?.logo !== null ? params?.logo[0] : null);

      formData.append("gender", params.gender);
      formData.append("city", location?._id);
      formData.append("about", params.description);
      formData.append("website", params.website);
      formData.append("linkedin", params.linkedin);
      formData.append("twitter", params.twitter);
      formData.append("tiktok", params.tiktok);
      formData.append("github", params.github);
      formData.append("birthday", newdate);
      formData.append("instagram", params.instagram);
      formData.append("firstname", params.firstname);
      formData.append("lastname", params.lastname);
      formData.append("personalEmail", params.personalemail);
      formData.append("foreign_student", params.isforeignstudent ? 1 : 0);


      params.tags.forEach((item) => {
        formData.append("tags[]", item._id);
      });
      const response = await AxiosInstance.post("user/update", formData, {
        headers: {
          "x-access-token": token,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },

  async fetchStudentDetails(token) {
    try {
      const response = await AxiosInstance.get("user/profile/me", {
        headers: { "x-access-token": token },
      });
      
      if (response.status === 200) {
        return response;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },

  async saveWorkData(params, token) {
    
    try {
      const response = await AxiosInstance.post(
        "/user_works",
        {
          headers: {
            "x-access-token": token,
          },
          ...params,
        },
        { headers: { "x-access-token": token } }
      );
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },


  async fetchSavedJobs(token) {
    try {
      const response = await AxiosInstance.get(
        "/save_job",
        {
          headers: {
            "x-access-token": token,
          },

        },

      );

      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },


  async fetchRecommendedJobs(token) {
    try {
      const response = await AxiosInstance.get(
        "/job/recommended",
        {
          headers: {
            "x-access-token": token,
          },

        },

      );

      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },



  async updateCertificateData(id, params, token) {
    
    try {
      const response = await AxiosInstance.post(
        `/user_certificates/${id}`,
        {
          headers: {
            "x-access-token": token,
          },
          ...params,
        },
        { headers: { "x-access-token": token } }
      );
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async updateWorkData(id, params, token) {
    
    try {
      const response = await AxiosInstance.post(
        `/user_works/${id}`,
        {
          headers: {
            "x-access-token": token,
          },
          ...params,
        },
        { headers: { "x-access-token": token } }
      );
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },


  async saveJob(token, id) {
    try {
      const response = await AxiosInstance.post(
        `/save_job/${id}`,
        {

        },
        { headers: { "x-access-token": token } }

      );

      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async deleteSaveJob(token, id) {
    

    try {
      const response = await AxiosInstance.delete(
        `/save_job/${id}`,

        {
          headers: { "x-access-token": token }

        });

      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async updateAchievementData(id, params, token) {
    
    try {
      const response = await AxiosInstance.post(
        `/user_achievements/${id}`,
        {
          headers: {
            "x-access-token": token,
          },
          ...params,
        },
        { headers: { "x-access-token": token } }
      );
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async saveCertificateData(params, token) {
    
    try {
      const response = await AxiosInstance.post(
        "/user_certificates",
        {
          headers: {
            "x-access-token": token,
          },
          ...params,
        },
        { headers: { "x-access-token": token } }
      );
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async saveAchievementData(params, token) {
    
    try {
      const response = await AxiosInstance.post(
        "/user_achievements",
        {
          headers: {
            "x-access-token": token,
          },
          ...params,
        },
        { headers: { "x-access-token": token } }
      );
      
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async deleteCertificate(id, token) {
    try {
      const response = await AxiosInstance.delete(`/user_certificates/${id}`, {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async deleteAchievement(id, token) {
    try {
      const response = await AxiosInstance.delete(`/user_achievements/${id}`, {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  // apply for job

  async applyForJob(token, id) {
    try {
      const response = await AxiosInstance.post(
        `/job_application/${id}`,
        {

        },
        { headers: { "x-access-token": token } }

      );

      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  async fetchTalentList(token, params) {
    try {
      const response = await AxiosInstance.get("/user/talents", {
        headers: { "x-access-token": token },
        params: { ...params }
      });
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },

  

 
  async deleteUserFromDB(token) {
    try {
      const response = await AxiosInstance.delete(`user/delete-info `, {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },
};

