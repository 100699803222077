import { createSlice } from "@reduxjs/toolkit";
import { CommonAPIS } from "utils/API/common-apis";

const initialState = {
  isLoggedIn: false,
  userType: null,
  userInfo: null,
  error: null,
  accessToken:null
};
export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },

    setUserType(state, action) {
      
      state.userType = action.payload;
    },

    setUserInfo(state, action) {
      
      state.userInfo = action.payload;
    },

    setLoggedInToken(state,action) {
      state.accessToken=action.payload
    }
  },
});

export const reducer = slice.reducer;

// export const setUserLoggedIn = (data) => (dispatch) => {
//   dispatch(slice.actions.setLoggedIn(data));
// };
// export const setUserInfo = (data) => (dispatch) => {
//   
//   slice.actions.setUserInfo(data);
// };

// export const setUserType = (data) => (dispatch) => {
//   dispatch(slice.actions.setUserType(data));
// };
// export const setLoggedInToken = (token) => (dispatch) => {
//   dispatch(slice.actions.setLoggedInToken(token));
// };

export const getUserProfile = (token) => async (dispatch) => {
  

  const response = await CommonAPIS.fetchUserDetails(token);
  

  if (response.status) {
    const data = response.data;
    if(data.success) {
      return response.data
    }
    else return response
  // } else {
  //   console.error(response.error);

  //   dispatch(slice.actions.setErrorStatus(true));
  }
};
export const {setLoggedInToken,setLoggedIn,setUserInfo,setUserType} = slice.actions;
export default slice;
