import * as React from "react";

import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import CustomButton from "components/common/CustomButton";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { lazy } from "react";
import LandingCard2 from "components/ladingCard2";
import { HelmetComponent } from "components/common/HelmetComponent";

import styles from "./educator-landing.module.scss"
import LandingFooter from "components/landingFooter";
import BannerEducator from "components/landingPage/BannerEducator";


const OurPartners = lazy(() => import("components/landingPage/OurPartners"));
const RegisterOnBoardModal = lazy(() => import("components/landingPage/RegisterOnBoardModal"))

const missionList = [
  {
    id: 1,
    heading: "Create an employer profile",
    text: "It takes 2 minutes, and it's completely free.",
  },
  {
    id: 2,
    heading: "Connect with us briefly",
    text: "So we can get to know you and support you.",
  },
  {
    id: 3,
    heading: "Post your first role",
    text: "We'll share it with smart, qualified, Canadian candidates. Your first three posts are complimentary.",
  },
  {
    id: 4,
    heading: "Reach out proactively",
    text: "Browse and filter profiles of talented job seekers and reach out directly to qualified candidates.",
  },
];


const responsiveCrousalS = {
  desktop: {
    breakpoint: { max: 3000, min: 1211 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1210, min: 691 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 690, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};


const EducatorLanding = () => {
  const { accessToken, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  const reviews = [
    {
      data: "The Allevar team is excited about partnering with Getting Ahead to help ambitious talent enter the job market. Kunal Chopra, and his team have created a game changing platform, and we are privileged to be a part of it!",
      name: "President, Allevar Consulting",
    },
    // {
    //   data: "Getting Ahead is a game changer by giving talent the opportunity to connect to businesses of all sizes, in need of talent but without the resources to find them. This platform will enable a community to grow between talents and employers, helping many more talents find jobs and gain invaluable experience",
    //   name: "Kyle H., McMaster",
    // },
    // {
    //   data: "Getting Ahead focuses on the crucial issue of talent visibility in relation to potential employers. Also, with its constant initiatives such as workshops and seminars across institutions, the platform is moving closer to creating a digital and physical community for talent, finding the proper fit in terms of potential employers. It’s correct to say that Getting Ahead is essentially fulfilling the need of the hour.",
    //   name: "Vinod K, Schulich School of Business ",
    // },
  ];
  const arr1 = [
    {
      name: "Access to student reviews for Universities and Colleges. ",
      des: "Hear from real talent about their experience at various Canadian Universities and Colleges, their career search strategies, and where they ended up.",
    },
    {
      name: "Exclusive Guides and Resources for Résumés, Interviews, Career Search Strategies, and More",
      des: "The most comprehensive information, updated to include information on virtual interviews, industry-specific résumés, and more. ",
    },
    {
      name: "Exclusive Networking Events, Webinars, and More…",
      des: "Get access to special events that matter to you, your job search, and your career. Learn how to break into your industry of choice, listen to how others found success with their job search, and meet industry experts.",
    },
    {
      name: "A Community that Connects Talent and Employers with Relevant Opportunities ",
      des: "No more scouring through entry-level job postings that require years of experience. Enjoy access to a highly curated job board with entry-level jobs that will kickstart your career in your chosen field.",
    },
  ];

  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => history.push("/employer-register");
  const handleClose = () => setOpen(false);

const seoData={
  canonical: {
    "href": "https://gettingahead.education/educators"
  },
  ogTitle: {
    "content": "Find jobs | Getting Ahead"
  },
  ogDescription: {
    "content": "Talent Acquisition, early career professionals and small & mid-sized businesses. School ratings, talent profiles, campus recruiting, jobs."
  },
  ogImage: {
    "content": "https://gettingahead.education/static/media/logo.f6ccefcc.svg",
    "type": "image/svg"
  }
}


  return (
    <React.Fragment>
      <HelmetComponent  {...seoData} />
      <Grid className={styles.landing}>
        <BannerEducator isLoggedIn={isLoggedIn} accessToken={accessToken} handleOpen={handleOpen} history={history} />
        
        {/* Start Footer */}
        <LandingFooter />
        {/* End Footer */}
      </Grid>

      {open && <RegisterOnBoardModal open={open} handleClose={handleClose} history={history} />}
    </React.Fragment>
  );
};
export default EducatorLanding;
