import * as React from "react";
import "./CustomButton.scss";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CustomButton = ({
  label,
  classType,
  onClick,
  isDisable,
  buttonType = "Button",
  isLoading = false,
  wrapperClass=""
}) => {
  return (
    <Box className={`CustomBtn ${wrapperClass}`}>
      <Button
      
        variant="contained"
        className={classType}
        onClick={onClick}
        disabled={isLoading ? true : isDisable}
        type={buttonType}
      >
        {isLoading ? <CircularProgress color="inherit" /> : label}
      </Button>
    </Box>
  );
};
export default CustomButton;
