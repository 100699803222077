import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 tagList:[],
 locationList:[],
 jobLevelList:[],
 industriesList:[],
 jobTypeList:[],
 salaryRangeList:[],
payPeriodList:[],
schoolList:[],
schoolData:[],
targetYearsList:[],
facultyList:[],
degreeList:[],
employerJobCurrentTab:"1"
};
export const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setTagList(state, action) {
      
      state.tagList = action.payload;
    },
    
    setLocationList(state, action) {
     
      state.locationList = action.payload;
    },
    
    setJobLevels(state, action) {
     
      state.jobLevelList = action.payload;
    },
    
    setIndustries(state,action){
       state.industriesList=action.payload;
    },

    
    setJobTypes(state, action) { 
      state.jobTypeList = action.payload;
    },
    setSalaryRange(state, action) { 
      state.salaryRangeList = action.payload;
    },
    
    setPayPeriodList(state, action) { 
      state.payPeriodList = action.payload;
    }, 
    setSchoolList(state, action) { 
   
      state.schoolList = action.payload;
    }, setSchoolData(state, action) { 
      state.schoolData = action.payload;
    },
    
    setTargetYears(state, action) { 
      state.targetYearsList = action.payload;
    },
    
    setFacultyList(state, action) { 
      
      state.facultyList = action.payload;
    },
    
    setDegreeList(state, action) { 
      state.degreeList = action.payload;
    },
    
    setEmployerJobsCurrentTab(state, action) { 
      state.employerJobCurrentTab = action.payload;
    },
  }
});

export const reducer = slice.reducer;
export const {setTagList,setLocationList,setJobLevels,setJobTypes,setSalaryRange,setPayPeriodList,setSchoolList,setSchoolData,setTargetYears,setFacultyList,setDegreeList,setEmployerJobsCurrentTab,setIndustries} = slice.actions;
export default slice;
