import * as React from "react";

import {
  Grid,
  Box,
  Typography,
  Link,

} from "@mui/material";
import CustomButton from "components/common/CustomButton";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "assets/svg-icon/logo.svg";
import Carousel from "react-multi-carousel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { NavLink } from "react-router-dom";
import LinkedInIcon from "assets/svg-icon/linkedin.svg";
import TwittweIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTokIcon from "assets/svg-icon/tiktokicon.svg";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { useSelector } from "react-redux";
import { lazy } from "react";
import Banner from "components/landingPage/Banner"
import LandingCard2 from "components/ladingCard2";
import PortalCard from "components/gettingAheadPortalCard";
import { HelmetComponent } from "components/common/HelmetComponent";
// import OurPartners from "components/landingPage/OurPartners";
// import Blogs from "components/landingPage/Blogs";
// import RegisterOnBoardModal from "components/landingPage/RegisterOnBoardModal";

import styles from "./landing.module.scss"
import LandingFooter from "components/landingFooter";


const OurPartners = lazy(() => import("components/landingPage/OurPartners"));
const Blogs = lazy(() => import("components/landingPage/Blogs"))
const RegisterOnBoardModal = lazy(() => import("components/landingPage/RegisterOnBoardModal")) 
const EmployerSaying = lazy(()=>import("components/landingPage/EmployerSaying"))

const missionList = [
  {
    id: 1,
    heading: "Set up a profile",
    text: "It only takes 2 minutes, and when you're ready, we'll show your profile to several small to mid-size employers.",
  },
  {
    id: 2,
    heading: "Browse employers and open jobs",
    text: "Getting Ahead works directly with startups and small to medium sized companies where you can do meaningful work and make real career progress faster than at huge faceless firms.",
  },
  {
    id: 3,
    heading: "Connect with employers",
    text: "You'll be able to apply for the role directly through Getting Ahead. Employers also have the option to reach out to you directly about open roles.",
  },
  {
    id: 4,
    heading: "Build your network",
    text: "Once you're a member, we'll connect you with networking events, and mentorship opportunities where you'll meet employers and build a powerful professional network.",
  },
];


const responsiveCrousalS = {
  desktop: {
    breakpoint: { max: 3000, min: 1211 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1210, min: 691 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 690, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};


const Landing = () => {
  const { accessToken, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  const reviews = [
    // {
    //   data: "Getting Ahead ends the divide between Talent and Employers. The platform bridges the two together, with detailed talent profiles and eager recruiters from small-large businesses. It provides a community that is purely user influenced, allowing for transparency and unbiased feedback on their education. Network, connect, and gain invaluable experience today, it’s time to GetAhead!",
    //   name: "Jaiden M., York University",
    // },
    // {
    //   data: "Getting Ahead is a game changer by giving talent the opportunity to connect to businesses of all sizes, in need of talent but without the resources to find them. This platform will enable a community to grow between talents and employers, helping many more talents find jobs and gain invaluable experience",
    //   name: "Kyle H., McMaster",
    // },
    // {
    //   data: "Getting Ahead focuses on the crucial issue of talent visibility in relation to potential employers. Also, with its constant initiatives such as workshops and seminars across institutions, the platform is moving closer to creating a digital and physical community for talent, finding the proper fit in terms of potential employers. It’s correct to say that Getting Ahead is essentially fulfilling the need of the hour.",
    //   name: "Vinod K, Schulich School of Business ",
    // },
    {
      data: "This platform has been incredibly helpful by connecting me with potential employers and enabling significant progress in my career!",
      name: "-Amoli Kar, Found her current tech role through Getting Ahead"
    },
    // {
    //   data: "This platform has been incredibly helpful by connecting me with potential employers and enabling significant progress in my career!",
    //   name: "-Amoli Kar, Found her current tech role through Getting Ahead"
    // },
    // {
    //   data: "This platform has been incredibly helpful by connecting me with potential employers and enabling significant progress in my career!",
    //   name: "-Amoli Kar, Found her current tech role through Getting Ahead"
    // },
  ];

  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => history.push("/student-register");
  const handleClose = () => setOpen(false);

const seoData={
  canonical: {
    "href": "https://gettingahead.education/"
  },
  ogTitle: {
    "content": "Find jobs | Getting Ahead"
  },
  ogDescription: {
    "content": "Find Jobs with small & mid-sized businesses. School ratings, talent profiles, campus recruiting, jobs."
  },
  ogImage: {
    "content": "https://gettingahead.education/static/media/logo.f6ccefcc.svg",
    "type": "image/svg"
  }
}


  return (
    <React.Fragment>
      <HelmetComponent  {...seoData} />
      <Grid className={styles.landing}>
        <Banner isLoggedIn={isLoggedIn} accessToken={accessToken} handleOpen={handleOpen} history={history} />
        {/* Start Job */}
        <Grid className={styles.job}>
          <Grid className="container">
            <h2>You're looking for a career you can actually get excited about</h2>
            <Typography><i>And common platforms aren't helping</i>. You know the algorithms aren't even showing your application to half of the jobs you apply for, and you're competing with thousands of applicants.</Typography>
            <Typography>You know there are more roles out there, but you can't find them. <b>Getting Ahead can help</b>.</Typography>
            <Typography><b>90% of businesses are small to medium sized</b>, we bring them right to you <b>and they're hiring</b>.</Typography>
            <Typography>Set up a profile, get connected with exciting jobs and apply directly on the platform.</Typography>
            {/* <Box className={styles.cta}>>
              {!isLoggedIn && accessToken === null ? (
                <CustomButton
                  classType="btn-primary2"
                  onClick={() => handleOpen()}
                  label="Create Your Free Talent or Employer Profile Here"
                  wrapperClass={styles.CustomBtn}

                />
              ) : (
                <CustomButton
                  classType={"btn-primary2"}
                  label="Go to your Profile"
                  onClick={() => history.push("/login")}
                  wrapperClass={styles.CustomBtn}

                />
              )}
            </Box> */}
          </Grid>
        </Grid>
        {/* End Job */}

        <OurPartners history={history} />

        {/* Start Student */} 
        <EmployerSaying heading={"Testimonial"} data={[...reviews]} />
        {/* <Grid className={styles.student}>
          <Grid className="container">
            <h2>Testimonial</h2>
            <Grid>
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                arrows={false}
                slidesToSlide={1}
                responsive={responsiveCrousalS}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={10000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={600}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {reviews?.map((item) => (
                  <LandingCard2 classes={styles.review} data={item?.data} name={item?.name} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Grid> */}
        {/* End Student */}

        {/* Start Mission */}
        <Grid className={styles.mission}>
          <Grid className="container">
            <h2>How it works</h2>
            <Grid container className={styles.mission_box} alignItems={"center"}>
              <Grid items xs={12} md={6} className={styles.text}>
                <ul>
                  {missionList.map((item, idx) => (
                    <li key={idx}>
                      <span className={styles.no}>{idx+1}.</span>
                      <h5>{item?.heading}</h5>
                      <Typography>{item?.text}</Typography>
                    </li>
                  ))}
                </ul>
                <CustomButton
                  label="I Want To Get Started"
                  onClick={handleOpen}
                  wrapperClass={styles.CustomBtn}
                />
              </Grid>
              <Grid items xs={12} md={6}>
                <img
                  loading="lazy"
                  src={"/images/howItWork.svg"} alt="hands"
                  width={223}
                  height={202} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* End Mission */}

        {/* Start Student */}
        <Grid className={styles.student}>
          <Grid className="container">
            <h2>Testimonial</h2>
            <Grid>
              <LandingCard2 classes={`${styles.fullCard} ${styles.review}`} data={"Getting Ahead is a game changer by giving talent the opportunity to connect to businesses in need of talent but without the resources to find them. This platform will enable a community to grow between talent and employers, helping people find jobs and gain invaluable experience"} name={"-Kyle H., McMaster"} />
            </Grid>
          </Grid>
        </Grid>
        {/* End Student */}


        {/* Start Joining */}
        <Grid className={styles.joining}>
          <Grid className="container">
            <h2>Why do I want to work for a small or mid-sized company?</h2>
            <Box className={styles.row}>
              <Box className={styles.box}>
                <h5>Stop competing with thousands of resumes</h5>
                <Typography>Big companies recruiting on campus and common platforms get thousands of applications for every role they post— but there are incredible companies who are hiring that will never set foot on your campus. Getting Ahead will put you in front of hundreds of employers you might learn about otherwise.</Typography>
              </Box>
              <Box className={styles.box}>
                <h5>Find fresh opportunities</h5>
                <Typography>90% of jobs are created by small to mid-size employers. If you only apply to the handful of big logos, (and hear nothing) you’re missing out on hundreds of incredible jobs.</Typography>
              </Box>
              <Box className={styles.box}>
                <h5>Actually do interesting work</h5>
                <Typography>Large companies hire employees and give them repetitive tasks. At smaller companies, there’s a lot of interesting things that need to get done. You’ll have the opportunity to learn faster, touch more areas of the business, and make a bigger impact early in your career.</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* End Joining */}


        {/* Start Footer */}
        <LandingFooter />
        {/* End Footer */}
      </Grid>

      {open && <RegisterOnBoardModal open={open} handleClose={handleClose} history={history} />}
    </React.Fragment>
  );
};
export default Landing;
