import { RestaurantMenuTwoTone } from "@mui/icons-material";
import * as React from "react";
import "./Form.scss";

const InputBox = ({
  label,
  placeholder,
  type,
  addClassName="",
  isReadOnly = false,
  name,
  register, required 
  ,error='',icon='',min,max,
  fileTypes,
  inputRef,step,mult,
  autoFocus=false,
  multiple=false

}) => {
  const [showPassword, setShowPassword] = React.useState(false);


  return (
    <div className={`form-group ${addClassName}`}>
      {label !== undefined ? <label>{label}</label> : ""}
      <div className="over">
        {register  ?
        <input
        type={
          type === "password" ? (showPassword ? "text" : "password") : type
        }
        placeholder={placeholder}
        className={`form-control ${icon!==''?'icon':null}`}
        ref={inputRef}
        autoFocus={autoFocus}

        readOnly={isReadOnly}
        // {...rest}
        min={min!==undefined?min:''}
        max={max!==undefined?max:''}
        accept={type==="file"?fileTypes:''}
        step={step}
        
        {...register(name.trim(), required===true && { required })}
      /> : <input
      type={
        type === "password" ? (showPassword ? "text" : "password") : type
      }
      placeholder={placeholder}
      className={`form-control ${icon!==''?'icon':null}`}
      readOnly={isReadOnly}
      min={min!==undefined?min:''}
      ref={inputRef}
      autoFocus={autoFocus}
      multiple={multiple}


      />}
        
        {type === "password" ? (
          <input

            type="checkbox"
            className="showPassword"
            onClick={(e) => setShowPassword((v) => !v)}
          />
        ) : (
          ""
        )}
        {icon && icon!== "/static/media/tiktokicon.923e7f4b.svg" && <div className="input-icon">{icon}</div>}

        {icon && icon==="/static/media/tiktokicon.923e7f4b.svg" && <div className="input-icon"><img  loading="lazy" src={icon} alt="tiktok" /></div>}


      </div>
      {error!=='' ?
              <p style={{fontSize:'14px'}} className="text-danger">{error}</p>
           :'' }
    </div>
  );
};
export default InputBox;
