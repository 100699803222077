import * as yup from "yup";
yup.setLocale({ mixed: { required: "This field is required." } });

yup.addMethod(yup.string, "password", password);

function password() {
  return this.matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  );
}

yup.addMethod(yup.string, "minStringLength", minStringLength);

function minStringLength(v) {
  return this.matches(
    /^[A-Za-z_ ]{3,}$/,

    `Must be minimum ${v} characters only contain alphabets`
  );
}

// .test("alphabets", "First Name must only contain alphabets", (value) => {
//     return /^[A-Za-z]+$/.test(value);
//   }
//   ),

yup.addMethod(yup.string, "matchInstagramLink", matchInstagramLink)
function matchInstagramLink(v) {
  // /(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)).*/g,

  return this.matches(
    /(https?:\/\/(?:www\.)?instagram\.com).*/g,
    {message: 'Not a valid link', excludeEmptyString: true }

  );
}

yup.addMethod(yup.string, "matchGithubLink", matchGithubLink)

function matchGithubLink(v) {
  return this.matches(
    /http(?:s)?:\/\/(?:www\.)?github\.com/,
    { message: 'Not a valid link', excludeEmptyString: true }

  );
}

yup.addMethod(yup.string, "matchLinkedInLink", matchLinkedInLink)
function matchLinkedInLink(v) {
  return this.matches(
    /http(?:s)?:\/\/(?:www\.)?linkedin\.com/, { message: 'Not a valid link', excludeEmptyString: true }

  );
}

yup.addMethod(yup.string, "matchTwitterLink", matchTwitterLink)
function matchTwitterLink(v) {
  // /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
  

  return this.matches(
    /http(?:s)?:\/\/(?:www\.)?twitter\.com/,
    { message: 'Not a valid link', excludeEmptyString: true }

  );
}


yup.addMethod(yup.string, "matchTiktokLink", matchTiktokLink)
function matchTiktokLink(v) {
  // /http(?:s)?:\/\/(?:www\.)?tiktok\.com\/([@])([a-zA-Z0-9])/,

  return this.matches(
    /http(?:s)?:\/\/(?:www\.)?tiktok\.com/,
    { message: 'Not a valid linkd', excludeEmptyString: true }
  );
}

yup.addMethod(yup.string, "onlyNumber", onlyNumber)

function onlyNumber () {
  return this.matches(/^[1-9]\d*(\.\d+)?$/, 'The field should have number only')

}
yup.addMethod(yup.string,"onlyAlphabets",onlyAlphabets)

function onlyAlphabets() {
  return this.matches(/^[^-\s][a-zA-Z0_\s-]+$/,'The field should have alphabets only')
}