import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import styles from "./landingFooter.module.scss";
import Logo from "../assets/svg-icon/logo.svg";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LinkedInIcon from "../assets/svg-icon/linkedin.svg";
import TwittweIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTokIcon from "../assets/svg-icon/tiktokicon.svg";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import CustomButton from "./common/CustomButton";
import { useSelector } from "react-redux";
import RegisterOnBoardModal from "./landingPage/RegisterOnBoardModal";

const socialShareLinks = [
  {
    id: 1,
    icon: LinkedInIcon,
    to: "https://www.linkedin.com/company/gettingahead/?viewAsMember=true",
  },
  { id: 2, icon: TwittweIcon, to: "https://twitter.com/GetAhead_Social" },
  {
    id: 3,
    icon: InstagramIcon,
    to: "https://www.instagram.com/gettingaheadplatform/",
  },
  { id: 4, icon: TikTokIcon, to: "https://vm.tiktok.com/ZMNdSEtQ3" },
  {
    id: 5,
    icon: YouTubeIcon,
    to: "https://www.youtube.com/channel/UC7C401os8a57c2J_Ay1_JsA",
  },
  {
    id: 6,
    icon: FacebookOutlinedIcon,
    to: "https://www.facebook.com/GettingAheadEducation-108291924405731",
  },
];

const LandingFooter = () => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { accessToken, isLoggedIn } = useSelector((state) => state.auth);

  return (
    <>
      <Grid className={styles.footer}>
        <Grid className="container">
          <Grid className={styles.row}>
            <Box className={styles.footerLogo}>
              <img
                loading="lazy"
                src={Logo}
                alt="logo"
                width={81}
                height={45}
                onClick={() => history.push("/")}
              />
            </Box>
            <Box className={styles.midd}>
              <Grid className={styles.contact} textAlign="center">
                <h2>Follow or Contact us</h2>
                <Typography component="ul" className={styles.share}>
                  {socialShareLinks.map((data) => (
                    <Typography component="li">
                      <a href={`${data?.to}`} target="_blank">
                        {data?.id === 1 || data?.id === 4 ? (
                          <img
                            loading="lazy"
                            src={data?.icon}
                            alt="LinkedIn"
                            width={30}
                            height={30}
                          />
                        ) : (
                          <data.icon />
                        )}
                      </a>
                    </Typography>
                  ))}
                </Typography>
                <p>If you have any questions, feel free to write us:</p>
                <Link href="mailto:team@gettingahead.education">
                  team@gettingahead.education
                </Link>
              </Grid>
            </Box>
            <Grid className={styles.footerRight}>
              {/* <Box className={styles.CandC}>
                  <Typography
                    variant="p"
                    onClick={() => history.push("/community")}
                    className={styles.Community}
                  >
                    Community
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => history.push("/company")}
                    className={styles.Company}
                  >
                    Company
                  </Typography>
                </Box> */}
              {!isLoggedIn && accessToken === null ? (
                <Box className={styles.cta}>
                  <CustomButton
                    classType={"btn-primary"}
                    onClick={() => handleOpen()}
                    label={"Register"}
                    wrapperClass={styles.CustomBtn}
                  />
                  <CustomButton
                    onClick={() => history.push("/login")}
                    classType={"btn-outline-primary"}
                    label={"Log in"}
                    wrapperClass={styles.CustomBtn}
                  />
                </Box>
              ) : (
                <Box className={styles.cta}>
                  <NavLink className={styles.list_inner} to={"/login"}>
                    My Account
                  </NavLink>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid className={styles.footer_terms}>
            <Typography
              id="ContactSectionId"
              component="div"
              className={styles.terms}
            >
              <NavLink to={"/terms"} target="_blank">
                Terms of Use
              </NavLink>

              <NavLink to={"/privacy"} target="_blank">
                Privacy policy
              </NavLink>
            </Typography>
            <Typography className={styles.copyright}>
              © Copyright Getting Ahead. All Rights Reserved. 2023
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {open && <RegisterOnBoardModal open={open} handleClose={handleClose} history={history} />}
    </>
  );
};

export default LandingFooter;
