import React from 'react'
import { Helmet } from 'react-helmet-async';

export const HelmetComponent = (props) => {
const {canonical,ogDescription,ogImage,ogTitle}=props

  return (
    <Helmet prioritizeSeoTags>
      <title>{ogTitle?.content ||  'Geting Ahead'}</title>
    <link rel="canonical" href={canonical?.href} />
    <meta name='description' content={ogDescription?.content} />
    <meta property="og:title" content={ogTitle?.content}/>
    <meta property="og:description" content={ogDescription?.content} />
    <meta property="og:image" content={ogImage?.content} />
    <meta property="og:image:type" content={ogImage?.type} />
  </Helmet>
  )
}
