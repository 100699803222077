import React from 'react';
import { Grid, Typography, Box } from '@mui/material'; // Import Material-UI components
import CustomButton from "components/common/CustomButton";
import styles from "./banner.module.scss";



const BannerEmployer = ({ isLoggedIn, accessToken, handleOpen, history }) => {
  return (
    <Grid className={styles.banner}>
      <Grid className="container">
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <h2>Your next hire is already here.</h2>
            <Box sx={{ maxWidth: "617px" }}>
              <Typography>You don't need to shell out for a booth on campus, compete with large employers on existing platforms or sift through thousands of digital resumes to find great candidates. Getting Ahead connects you directly to incredible talent looking for opportunities at your business.</Typography>
              <Box className={isLoggedIn && accessToken === null ? styles.cta : styles.cta_left}>
                {!isLoggedIn && accessToken === null ? (
                  <Box>
                    <CustomButton
                    classType={styles.btn_join}
                      label="Create A Free Profile"
                      onClick={handleOpen}
                      wrapperClass={styles.CustomBtn}
                    />
                  <Typography sx={{ mt: '20px', fontStyle: 'italic' }}>
                  It's completely free and, you can post a job to our network of talent today
                  </Typography>
                  </Box>
                ) : (
                  <CustomButton
                  classType={styles.btn_join}
                    label="Go to your Profile"
                    onClick={() => history.push("/login")}
                    wrapperClass={styles.CustomBtn}

                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
             loading="lazy"
              src="/images/home-banner-img1.webp"
              alt="The place where tomorrow's industry leaders get their start."
              width={618}
              height={548}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BannerEmployer;
