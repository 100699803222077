import * as React from "react";
import "../sass/header.scss";
import { useHistory, useLocation } from "react-router-dom";
import { Typography, Box, Menu, MenuItem, Button } from "@mui/material";
import Logo from "../assets/svg-icon/logo.svg";
import MenuBar from "../assets/svg-icon/menubar.svg";
import CloseMenuBar from "../assets/svg-icon/closemenu.svg";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DefaultImg from "assets/images/defaultavatar.webp";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setLoggedInToken, setUserInfo } from "slices/auth-slice";
import { FilePath } from "utils/constants";
import { setEmployerJobsCurrentTab } from "slices/ui-slice";
import { lazy } from "react";
const AddSchoolContact = lazy(() => import("components/Popups/AddSchoolContact"));


const Header = () => {
  const {  userInfo,userType } = useSelector((state) => state.auth);


  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const showMenu = () => {
    setOpenMenu((v) => !v);
  };

  const [schoolAnchorEl, setSchoolAnchorEl] = React.useState(null);
  const openSchoolDD = Boolean(schoolAnchorEl);
  const handleSchoolClick = (event) => {
    setSchoolAnchorEl(event.currentTarget);
  };
  const handleSchoolDD = (type) => {
    if (type?.name === "Explore schools") {
      history.push("/top-rated");
      setSchoolAnchorEl(null);
    } else if(type?.name === "Compare schools") {
      history.push("/compare-school");
      setSchoolAnchorEl(null);
    }
    else {
      // openAddSchool()
      setModal(true)
    }
    setOpenMenu(!openMenu);
  };

  



  const options = [
    {
      id: 101,
      name: "Explore schools",
      href: "schoolCardSection",
      to: "/top-rated",
    },
    {
      id: 102,
      name: "Compare schools",
      href: "schoolCompareSection",
      to: "/compare-school",
    },
    
    {
      id: 103,
      name: "Add a school faculty or program ",
      href: "",
      to: "",
    },
  ];

  let username=userInfo?.userInfo?.username
  

  const navbar = [
    { id: 1, name: "Home", value: "home", child: [], userTo: "/feed" , employerTo:`/employer/${username}` },
    { id: 2, name: "Jobs", value: "jobs", child: [], userTo: "/jobs", employerTo:`/employer-jobs/${username}` },
    {
      id: 3,
      name: "Explore Schools",
      value: "explore schools",
      child: options,
      to: "ggg",
    },
    { id: 5, name: "Talent", value: "talent", child: [], userTo: "", employerTo:`/talents` },
    { id: 6, name: "Employers", value: "employers", child: [], userTo: "/all-employers", employerTo:`/all-employers` }, 
    // {id: 7 , name: "Talent Toolkit", value:"talentToolkit" , child:[] , userTo :"/talent-toolkit" , employerTo: "/talent-toolkit"} 

  ];

  const getDynamicNavbar=()=> {
    if(userType==="employer") 
    return navbar.filter(me=>me.id!==3)
    else
    return navbar.filter(me=>me.id!==5)
  }

  const onNavBarClick = (e, navitem) => {
    if (navitem.value !== "explore schools") {
      handleNavigation(navitem)

    } else handleSchoolClick(e);
  };

  const setActiveClass = (data) => {
    let pathname=userType==='student'?data?.userTo:data?.employerTo
    if (data.child?.length === 0) {
     
      if (pathname === location.pathname) return true;
      else return false
    } 
    else {
      return data.child.some((me) => me.to === location.pathname);

    }
    
    
  };

  const handleNavigation = (data) => {
    // if( userType === "employer" && userInfo?.status === 0 && data.value !== "home"){
    //   toast.error("Your account is being reviewed by Getting Ahead to ensure the data privacy of our members. We will contact you within 24 hours.")
    // }
     if (userType === "employer") {
      let username = userInfo?.userInfo?.username;
      if (data.value === 'jobs') {
        return history.push(data?.employerTo)
        // dispatch(setEmployerJobsCurrentTab("2"))
      }
      else if (data.value === "home") {
        dispatch(setEmployerJobsCurrentTab("1"))
      setOpenMenu(!openMenu);
      return history.push("/employer/" + username)
      }
      else {
      return history.push(data?.employerTo)

      }
    }
    else {
      history.push(data.userTo);
      setOpenMenu(!openMenu);


    }

  }


  const onLogoutClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('username');

    dispatch(setUserInfo(null));
    dispatch(setLoggedInToken(null));
    dispatch(setLoggedIn(false));
    history.replace("/login");
  };





  const getImageUrl = () => {
    if (userInfo?.role === "employer") {
      if (userInfo?.userInfo.logo === undefined || userInfo?.userInfo.logo === "") {
        return DefaultImg;

      } else {
        let image = FilePath + userInfo?.userInfo.logo;
        return image;
      }
    } else {
      if (userInfo?.userInfo.image === undefined || userInfo?.userInfo.image === "") {
        return DefaultImg;

      } else {
        let image = FilePath + userInfo?.userInfo.image;
        return image;
      }
    }
  };
  const onProfileClick = () => {
    if (userInfo?.role === "employer")
      return history.push("/employer/" + userInfo?.userInfo?.username)
    else
      return history.push("/student-profile/" + userInfo?.userInfo?.username)
  }

  const onCloseModal=()=> {
    setModal(false)
    setSchoolAnchorEl(null)
  }


  return (
    <>
      <Box component="header" className="header afterLogin">
        <div className="container">
          <div className="cover">
            <Box className="logo" onClick={()=>history.push("/")}>
              <img  loading="lazy" src={Logo} alt="Getting Ahead"  width={81} height={45}/>
            </Box>
            <img  loading="lazy"  
              className="menuBar"
              onClick={showMenu}
              src={MenuBar}
              alt="Menubar"
            />
            <nav className={openMenu ? "Menu showMenu" : "Menu"}>
              <img
                className="closemenuBar"
                onClick={showMenu}
                src={CloseMenuBar}
                alt="Menubar"
              />
              <Typography component="ul">
                {getDynamicNavbar().map((data) => (
                  <Typography component="li" key={data.id}>
                    <span
                      onClick={(e) => onNavBarClick(e, data)}
                      className={setActiveClass(data) ? "active" : ""}
                    >
                      {data.name}
                    </span>
                  </Typography>
                ))}
              </Typography>
            </nav>
           { userInfo && <Box className="right">
              <Button onClick={handleClick} className="User">
                <span className="User_Name">
                  {" "}


                  {userInfo?.role === 'employer' ? userInfo?.userInfo?.name :  userInfo?.userInfo?.firstname + " " + userInfo?.userInfo?.lastname}
                  <ArrowDropDownIcon />
                </span>
                <span className="User_Img">
                  <img  loading="lazy" src={getImageUrl()} alt="John Deo" />
                </span>
              </Button>
            </Box>}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              className="menuBox"
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter:
                    "drop-shadow(20px 20px 50px rgba(238, 241, 248, 0.5))",
                  mt: 1.5,
                  minWidth: "245px",
                  borderRadius: "16px",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => onProfileClick()}>
                My profile
              </MenuItem>
             {userType==='student' && <MenuItem onClick={() => history.push("/jobs")}>Saved jobs</MenuItem>}
              <MenuItem onClick={() => onLogoutClick()}>Log out</MenuItem>
            </Menu>
          </div>
        </div>
      </Box>

      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={schoolAnchorEl}
        open={openSchoolDD}
        onClose={() => setSchoolAnchorEl(null)}
        className="listoptonsMenu"
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={() => handleSchoolDD(option)}>
            <span>{option.name}</span>
          </MenuItem>
        ))}
      </Menu>
      {modal && <AddSchoolContact open={modal}  closeOptions={()=>onCloseModal()}/>}
    </>
  );
};

export default Header;
