import { createSlice } from "@reduxjs/toolkit";
import { StudentAPIS } from "utils/API/student-apis";

const initialState = {
  profile: {},
  responseMessage: "",
};
export const slice = createSlice({
  name: "student",
  initialState,
  reducers: {
    setErrorStatus(state, action) {
      
    },
    setStudentProfile: (state, action) => {
      const data = action.payload;
      state.profile = data;
    },
  },
});

export const reducer = slice.reducer;

export const registerStudent = (data) => async (dispatch) => {
  
  const response = await StudentAPIS.createStudent(data);
  if (response?.data) {
    dispatch(
      slice.actions.setStudentProfile({
        status: true,
        message: "Success",
        data: data,
      })
    );
  } else {
    console.error(response);
  }
};

export const getStudentProfile = (token) => async (dispatch) => {
  
  const response = await StudentAPIS.fetchStudentDetails(token);
  

  if (response.status) {
    const data = response.data;
    dispatch(slice.actions.setStudentProfile(data));
  } else {
    console.error(response.error);

    dispatch(slice.actions.setErrorStatus(true));
  }
};

// Action creators are generated for each case reducer function

export default slice;
