import axios from "axios";
import { toast } from "react-toastify";
import { setLoggedIn, setLoggedInToken, setUserInfo } from "slices/auth-slice";
import { store } from "store/store";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const AxiosInstance = axios.create({
  // baseURL: BASE_URL,
  // // baseURL: "https://gettingahead.education:4200/api/v1",
  //  baseURL: "http://192.168.1.174:4300/api/v1/",
  baseURL: BASE_URL, 
  // // baseURL : "http://localhost:4300/api/v1/",


  timeout: 235000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }, 
});

function setTimeout(){
  setTimeout(window.location.replace('/admin'), 5000);

}

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401 && error?.response?.data?.type === "admin") {
      toast.error("Your token expired please login again");
      localStorage.removeItem("adminToken");
      localStorage.removeItem("adminLogin");
     return  setTimeout()
    }
    if (error?.response?.status === 401) {
      toast.error("Your token expired please login again");

      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      store.dispatch(setUserInfo(null));
      store.dispatch(setLoggedInToken(null));
      store.dispatch(setLoggedIn(false));
      return false;
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
