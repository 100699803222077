import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./utils/rules"
import { store } from './store/store'
import { Provider } from 'react-redux'
const LazyToastContainer = lazy(() => import('react-toastify').then((module) => ({ default: module.ToastContainer })));


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <Suspense fallback={null}>
      <LazyToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="colored"
      />
      </Suspense>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
  // 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
