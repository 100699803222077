import * as React from "react";
import { useSelector } from "react-redux";
import {
  Route,
  useLocation,
  Redirect,
  matchPath,
} from "react-router-dom";
import { toast } from "react-toastify";

const TempCompone = ({ parentCompo }) => {
  const location = useLocation();
  const [waiting, setWaiting] = React.useState(true);

  React.useEffect(() => {
    if (location.pathname.includes("feed-details")) {
      const link = location.pathname;
      localStorage.removeItem("postLink");
      localStorage.setItem("postLink", link);
    } else if (location.pathname.includes("job-description")) {
      const link = location.pathname;
      localStorage.removeItem("jobLink");
      localStorage.setItem("jobLink", link);
    }


    setWaiting(false);
  }, [location.pathname]);
  if(location.pathname.includes("admin-panel")){
    return <Redirect to={"/admin"} />;
  }
  else if (waiting) return null;
  return <Redirect to={"/login"} />;
};

export const ProtectedRoutes = ({ component: Component,path, ...rest }) => {
  const { isLoggedIn, userType, userInfo } = useSelector(
    (state) => state.auth
  );
  const [isAuthenticated, setAuthenticated] = React.useState(
    localStorage.getItem("token")
  );

  React.useEffect(() => {
    setAuthenticated(localStorage.getItem("token"));
  }, [isLoggedIn]);

  const id = userInfo?.userInfo?.username;
  const isEmployee = userInfo?.role === "employer";
  const canAccessOther = userInfo?.status === 1;

  const isSpecialPath = (location) => {
    const requireSpecialAccessPath = [
      "/employer-jobs/:id",
      "/talents",
      "/all-employers",
    ];
    let result = false;
    requireSpecialAccessPath.forEach((sp) => {
      if (!result) {
        result = !!matchPath(location?.pathname, {
          path: sp,
          exact: true,
          strict: false,
        });
      }
    });
    return result;
  };
  const renderRoute = (location) => {
    if (isEmployee && isSpecialPath(location)) {
      const redirectPath = "employer";
      if (!canAccessOther) {
        toast.error(
          "Your account is being reviewed by Getting Ahead to ensure the data privacy of our members. We will contact you within 24 hours."
        );
        return <Redirect to={`/${redirectPath}/${id}`} />;
      }
    }
    if (rest.canAccessOnly === "admin" && localStorage.getItem("adminLogin") === "admin") return <Component {...rest} />;
    else if (userType === rest.canAccessOnly || rest.canAccessOnly === "")
      return <Component {...rest} />;
      else if (rest.canAccessOnly === "admin") return <Redirect to={"/admin"} />;
    else return <Redirect to={"/"} />;
  };

  const renderRouteAdmin = (location) => {
    if (rest.canAccessOnly === "admin" && localStorage.getItem("adminToken")){
      return <Component {...rest} />;
    }
    else if(location.pathname.includes('/employer/') && localStorage.getItem("adminToken") ){
      return <Component {...rest} />;
    }
    else{
      return <Redirect to={"/admin"} />;
    }
     
  };

  return (
    <Route
    path={path}
      render={({ location, match }) =>
        isLoggedIn || isAuthenticated !== null ? (
          renderRoute(location)
        ) : (localStorage.getItem("adminLogin") === "admin" ? (
          renderRouteAdmin(location)
        ) : (
          <>
            <TempCompone Component={Component} />
          </>
        ))
      }
    />
  );
};
