import { combineReducers } from '@reduxjs/toolkit';
import { reducer as studentReducer } from '../slices/student-slice';
import {reducer as uiReducer} from "slices/ui-slice"
import {reducer as authReducer} from "slices/auth-slice"

const rootReducer = combineReducers({
  student:studentReducer,
  ui:uiReducer, 
  auth:authReducer
});

export default rootReducer;