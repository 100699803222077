import React from 'react';
import { Grid, Typography, Box } from '@mui/material'; // Import Material-UI components
import CustomButton from "components/common/CustomButton";
import styles from "./banner.module.scss";



const Banner = ({ isLoggedIn, accessToken, handleOpen, history }) => {
  return (
    <Grid className={styles.banner}>
      <Grid className="container">
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <h2>You haven't heard of 90% of the incredible companies hiring right now.</h2>
            <Box sx={{ maxWidth: "617px" }}>
              <Typography variant="h5">
              Let us introduce you
              </Typography>
              <Typography>
              90% of employers are small to medium sized businesses and they're hiring. Set up a profile, and connect directly with incredible career opportunities in your field.
              </Typography>
              <Box className={isLoggedIn && accessToken === null ? styles.cta : styles.cta_left}>
                {!isLoggedIn && accessToken === null ? (
                  <Box>
                    <CustomButton
                    classType={styles.btn_join}
                      label="Set Up A Profile"
                      onClick={handleOpen}
                      wrapperClass={styles.CustomBtn}
                    />
                  <Typography sx={{ mt: '20px', fontStyle: 'italic' }}>
                  It takes 2 minutes and it's free!
                  </Typography>
                  </Box>
                ) : (
                  <CustomButton
                  classType={styles.btn_join}
                    label="Go to your Profile"
                    onClick={() => history.push("/login")}
                    wrapperClass={styles.CustomBtn}

                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <img 
              loading="lazy"
              src="/images/home-banner-img.webp"
              alt="The place where tomorrow's industry leaders get their start."
              width={618}
              height={548}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Banner;
