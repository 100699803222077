import { Box, FormControlLabel, Grid, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import CustomButton from 'components/common/CustomButton'
import React from 'react'
import close from "assets/svg-icon/close.svg";
import Employer from "assets/images/employer-img.webp";
import "./onboard.scss"

const RegisterOnBoardModal = ({open,handleClose,history}) => {
  const [registerType, setRegisterType] = React.useState("/student-register");

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="registerModal"
  >
    <Box className="registerModal_Box" textAlign="center">
      <img
        loading="lazy"

        src={close}
        onClick={handleClose}
        className="registerModal_Box--close"
        alt="close"
      />
      <Typography component="h2" className="Title">
        You are:
      </Typography>
      <RadioGroup
        aria-label="Register Type"
        defaultValue="/student-register"
        name="radio-buttons-group"
      >
        <Grid container spacing={2}>
          <Grid item sm={6} className="registerModal_Box--item">
            <Box className="cover">
              <Box className="img">
                <img
                 loading="lazy"
                  width={110}
                  height={110}
              
                  src={"/images/student-img.webp"} alt="talent" />
              </Box>
              <FormControlLabel
                value="/student-register"
                control={<Radio />}
                onChange={(e) => setRegisterType(e.target.value)}
                label="Talent"
              />
            </Box>
          </Grid>
          <Grid item sm={6} className="registerModal_Box--item">
            <Box className="cover">
              <Box className="img">
                <img
                  loading="lazy"
                  src={Employer} alt="Employer" />
              </Box>
              <FormControlLabel
                value="/employer-register"
                control={<Radio />}
                onChange={(e) => setRegisterType(e.target.value)}
                label="Employer"
              />
            </Box>
          </Grid>
        </Grid>
      </RadioGroup>
      <Typography component="ul">
        <Typography component="li">
          <CustomButton
            label={"Continue"}
            onClick={() => {
              history.push(registerType);
              handleClose()
            }}
            classType={"btn-primary"}
          />
        </Typography>
        <Typography component="li">
          <CustomButton
            onClick={handleClose}
            label={"Cancel"}
            classType={"btn-outline-primary"}
          />
        </Typography>
      </Typography>
    </Box>
  </Modal>
  )
}

export default RegisterOnBoardModal
