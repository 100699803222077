import axios from "axios";
import { toast } from "react-toastify";
import { AxiosInstance } from "utils/axios/AxiosProvider";
import { Auth0Api } from "./auth0-api";
export const CommonAPIS = {

  async verifyUserName(params) {
    try {
      let response = await AxiosInstance.post("/user/check", {
        ...params,
      });
      if (response.status === 200) return response.data;
    } catch (e) {

      return e;
    }
  },

  async userLogin(params) {
    try {

      let response = await Auth0Api.doLoginAtAuth0(params);
      // 
      if (response) {
        return response;
      } else return response;
    } catch (e) {

      return e;
    }
  },




  async userLogout() {
    try {


      let response = await Auth0Api.logoutFromAuth0();
      // 
      if (response) {
        return response;
      } else return response;
    } catch (e) {

      return e;
    }
  },



  async AdminLogin(params) {
    try {
      let response = await AxiosInstance.post("/admin/login", {
        ...params
      })
      if (response?.status === 200) {
        return response?.data
      }
      else {
        return response?.error
      }

    } catch (error) {
      toast.error(error)
      return error
    }
  },

  async getAdminEmpList(token) {
    try {
      let response = await AxiosInstance.get("/admin/employerList", {
        headers: { "x-access-token": token },
      })
      if (response?.status === 200) {
        return response?.data
      }
      else {
        return response?.error
      }
    } catch (error) {
      toast.error(error)
      return error
    }
  },

  async AdminForget(params, token) {
    try {
      const response = await AxiosInstance.post('/admin/changePassword', {
        ...params
      },
        { headers: { "x-access-token": token } }
      );
      if (response?.data?.success) {
        return response?.data
      }
      else {
        return response?.data
      }
    } catch (error) {
      toast.error(error)
      return error
    }
  },

  async deleteEmpAdmin(params, token) {
    try {
      let response = await AxiosInstance.post("/admin/employer/delete", {
        ...params
      },
        {
          headers: { "x-access-token": token }
        })
      if (response?.data?.success) {
        return response?.data
      }
      else {
        return response?.error
      }
    }
    catch (error) {
      return error
    }
  },

  async chngStatusAdmin(params, token) {
    try {
      let response = await AxiosInstance.post("/admin/employer/update-status", {
        ...params
      },
        {
          headers: { "x-access-token": token }
        })
      if (response?.data?.success) {
        return response?.data
      }
      else {
        return response?.error
      }
    }
    catch (error) {
      return error
    }
  },

  async chngIsFeatAdmin(params, token) {
    try {
      let response = await AxiosInstance.post("/admin/employer/update-isFeatured", {
        ...params
      },
        {
          headers: { "x-access-token": token }
        })
      if (response?.data?.success) {
        return response?.data
      }
      else {
        return response?.error
      }
    }
    catch (error) {
      return error
    }
  },


  async registerStudent(params) {
    try {
      const response = AxiosInstance.post("/create/s", {
        ...params,
      });

      if (response) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },

  async doLogin(params) {
    try {
      const response = AxiosInstance.post("/login", {
        ...params,
      });

      if (response) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },

  async fetchLocationList() {
    try {
      const response = await AxiosInstance.get(`/location`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async getTermsContent() {
    try {
      const response = await AxiosInstance.get(`/cms/terms_of_use`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },


  async getPrivacyContent() {
    try {
      const response = await AxiosInstance.get(`/cms/privacy_policy`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchPromotedContent() {
    try {
      const response = await AxiosInstance.get(`/promote_content`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchEducationLevels() {
    try {
      const response = await AxiosInstance.get(`/level`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchJobLevels() {
    try {
      const response = await AxiosInstance.get(`/job_levels`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchJobTypes() {
    try {
      const response = await AxiosInstance.get(`/job_types`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchSalaryRange() {
    try {
      const response = await AxiosInstance.get(`/salary`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchPayPeriods() {
    try {
      const response = await AxiosInstance.get(`/pay_periods`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchSchoolList(token, params = null) {

    try {
      const response = await AxiosInstance.get(`/schools`, {
        headers: { "x-access-token": token },
        params: params

      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchTagList() {
    try {
      const response = await AxiosInstance.get(`/tag`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },


  async fetchTargetYears() {
    try {
      const response = await AxiosInstance.get(`/target_years`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchFaculties() {
    try {
      const response = await AxiosInstance.get(
        "/faculty",
      );

      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },

  async addFaculty(token, params) {
    try {
      const response = await AxiosInstance.post(
        "admin/faculty/update", {
        ...params,
      }
        ,
        { headers: { "x-access-token": token } }

      );

      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },
  
  
  
  async deleteFaculty(token, id) {
    try {
      const response = await AxiosInstance.delete(
        "admin/faculty/"+id
        ,
        { headers: { "x-access-token": token } }

      );

      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },


  async updateFaculty(token, params, id) {
    try {
      const response = await AxiosInstance.post(
        "admin/faculty/update/" + id, {
        ...params,
      }
        ,
        {
          headers: { "x-access-token": token }
        })
      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },
  async fetchDegrees(token, searchParam) {
    let endpoint = searchParam === undefined ? "/degree_program" : `/degree_program?search=${searchParam}`
    try {
      const response = await AxiosInstance.get(
        endpoint,
      );

      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },

  async fetchJobList(token, params) {
    // 

    try {
      const response = token? await AxiosInstance.get(`/job`, {
        headers: { "x-access-token": token },

        params: { ...params }
      },
      ) : await AxiosInstance.get(`/job`, {
      params: { ...params }
      },
      )
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchEmployesRange() {
    try {
      const response = await AxiosInstance.get(`/employee_numbers`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchEmployerListing(token, params) {
    try {
      const response = token? await AxiosInstance.post(`/user/employer`, {
        ...params
      },
        { headers: { "x-access-token": token } }
      ) : await AxiosInstance.post(`/user/employer`, {
        ...params
      },
      )
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async fetchUserDetails(token) {

    try {
      const response = await AxiosInstance.get("user/profile/me", {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },


  async getMyPosts(token) {
    try {
      const response = await AxiosInstance.get("post", {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },

  async fetchUserDetailsByUsername(username) {

    try {
      const response = await AxiosInstance.post(`/user/getByUsername`, {

        username

      });
      if (response.status === 200) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },


  async fetchEmployerFeedsByUsername(token, username) {

    try {
      const response = await AxiosInstance.get(`/post/user/${username}`,
        {
          headers: { "x-access-token": token },
        });
      if (response.status === 200) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },

  async requestForResetPassword(email) {
    try {
      let response = await Auth0Api.resetPasswordMail(email);


      if (response) {
        return response
      } else {

        return response;
      }
    } catch (e) {

      return e;
    }
  },


  async addPost(params, token) {


    const formData = new FormData();
    formData.append("description", params.description)
    formData.append("post_file", params.post_file)
    formData.append("post_type", params.post_type)
    formData.append("post_link", params.post_link)
    if (params.tags?.length > 0) {
      params.tags.forEach((item) => {
        formData.append("tags[]", item);
      });
    }
    try {
      const response = await AxiosInstance.post(`/post`, formData, {
        headers: { "x-access-token": token },

      });
      if (response.status === 200) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },

  async fetchFeedDetails(token, postId) {
    try {
      const response = await AxiosInstance.get(`post/${postId}`, {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },


  async deletePost(token, postId) {
    try {
      const response = await AxiosInstance.delete(`post/${postId}`, {
        headers: { "x-access-token": token },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return response.error;
      }
    } catch (error) {
      return error;
    }
  },

  async GetBlogPost() {
    try {
      let response = await axios.get("https://gettingahead.education/blog/wp-json/wp/v2/posts/?per_page=3&status=publish")
      if (response.status === 200) {
        return response.data
      }
      else {
        return response.error
      }
    }
    catch (error) {
      return error
    }
  },

  async likePost(token, id) {

    try {
      const response = await AxiosInstance.post(
        "/post/like",
        {

          id
        },
        { headers: { "x-access-token": token } }

      );
      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },

  async commentOnPost(token, params) {

    try {
      const response = await AxiosInstance.post(
        "/post/comment",
        {
          ...params
        },
        { headers: { "x-access-token": token } }

      );
      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },


  async sharePost(token, params) {

    try {
      const response = await AxiosInstance.post(
        "/post/share",
        {
          ...params
        },
        { headers: { "x-access-token": token } }

      );
      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },



  async reportSpam(token, id) {

    try {
      const response = await AxiosInstance.post(
        "/post/report",
        {
          id
        },
        { headers: { "x-access-token": token } }

      );
      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  },

  async addSchool(token, params) {

    try {
      const response = await AxiosInstance.post(
        "/school-request/add",
        {
          ...params
        },
        { headers: { "x-access-token": token } }

      );
      if (response.status === 200) return response.data;
    } catch (error) {

      return error;
    }
  }, 

  async getIndustries(token) {
    try{ 
      const response = await AxiosInstance.get("industry/industries-list", 
      { headers: { "x-access-token": token } }
      )
      if (response.status === 200) return response.data;
    }catch(error){
      return error
    }
  }, 
   
  async  getTalentToolkit() {
    try{
      const response = await AxiosInstance.get("talentToolkit/list")
      if (response.status === 200) return response.data;
    }catch(error){
      return error
    }
  }

};
