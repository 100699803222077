import React from 'react';
import { Grid, Typography, Box } from '@mui/material'; // Import Material-UI components
import CustomButton from "components/common/CustomButton";
import styles from "./banner.module.scss";



const BannerEducator = ({ isLoggedIn, accessToken, handleOpen, history }) => {
  return (
    <Grid className={styles.banner}>
      <Grid className="container">
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <h2>Coming soon - Exciting partnerships with education institutions and providers who are eager to see there graduates get great jobs.</h2>
            <Box sx={{ maxWidth: "617px" }}>
              <Typography variant="h5">
              Let us Enable you
              </Typography>
              <Typography>Talent is increasingly focused on the job outcomes from investments in higher education. Getting Ahead gives educators the ability to scale their career centers while connecting their talent with relevant jobs, support and guidance.</Typography>
              <br />
              <Typography>Contact us at <b><a href="mailto:team@gettingahead.education">team@gettingahead.education</a></b></Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <img 
             loading="lazy"
              src="/images/home-banner-img2.webp"
              alt="Education"
              width={618}
              height={548}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BannerEducator;
