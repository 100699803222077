import * as React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";

import Logo from "assets/svg-icon/logo.svg";
import MenuBar from "assets/svg-icon/menubar.svg";
import CloseMenuBar from "assets/svg-icon/closemenu.svg";
import RegisterOnBoardModal from "components/landingPage/RegisterOnBoardModal";

import { NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "components/common/CustomButton";
import styles from "./loginHeader.module.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const topbarMenuList = [
  {
    label: "Talent",
    path: "/",
  },
  {
    label: "Employers",
    path: "/employers",
  },
  {
    label: "Educators",
    path: "/educators",
  },
  {
    label: "About us",
    path: "/about-us"
  }
];

const TopBar = ({ isLoggedIn, accessToken, bgColor, handleOpen, handleClose }) => {
  const navigate = useHistory();
  const history = useHistory();
  // const [open, setOpen] = React.useState(false);
  // const { accessToken, isLoggedIn } = useSelector((state) => state.auth);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <Grid className={styles.topbar} sx={{ backgroundColor: bgColor }}>
      <Box className="container">
        <Typography component="ul">
          {topbarMenuList.map((item) => (
            <Typography key={item.label} component="li">
              <NavLink
                className={
                  navigate.location.pathname === item.path
                    ? styles.active
                    : null
                }
                to={item.path}
              >
                {item.label}
              </NavLink>
            </Typography>
          ))}

          <Typography className={styles.cta} component="li">
            {!isLoggedIn && accessToken === null ? (
              <>
                <CustomButton
                  wrapperClass={styles.btnOutline}
                  label={"Log in"}
                  onClick={() => history.push("/login")}
                  classType={"btn-outline-primary"}
                />
                <CustomButton
                  wrapperClass={styles.btnPrimary}
                  label={"Register"}
                  onClick={() => handleOpen()}
                  classType={"btn-primary"}
                />
              </>
            ) : (
              <NavLink className={styles.list_inner} to={"/login"}>
                My Account
              </NavLink>
            )}
          </Typography>
        </Typography>
      </Box>
    </Grid>
  );
};

const LoginHeader = ({ topBarBg, hideHeader }) => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const { accessToken, isLoggedIn } = useSelector((state) => state.auth);

  const showMenu = () => {
    setOpenMenu((v) => !v);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <TopBar handleOpen={handleOpen} handleClose={handleClose} accessToken={accessToken} isLoggedIn={isLoggedIn} bgColor={topBarBg} />
      {!hideHeader && <Box
        component="header"
        className={`${styles.header} ${styles.beforeLogin} ${location?.pathname?.includes("/about-us") && styles.backGroundWhite
          }`}
      >
        <Box className="container">
          <Box className={styles.cover}>
            <Box className={styles.logo}>
              <img
                loading="lazy"
                src={Logo}
                alt="Getting Ahead"
                width={81}
                height={45}
                onClick={() => history.push("/")}
              />
            </Box>
            <img
              className={styles.menuBar}
              onClick={showMenu}
              src={MenuBar}
              alt="Menubar"
            />
            <Box
              className={`${styles.right} ${openMenu ? styles.showMenu : ""}`}
            >
              <img
                className={styles.closemenuBar}
                onClick={showMenu}
                src={CloseMenuBar}
                alt="Menubar"
              />
              <Typography className={styles.lists} component="ul">
                <Typography component="li">
                  <a
                    className={styles.list_inner}
                    href="https://gettingahead.education/blog"
                  >
                    Blog
                  </a>
                </Typography>
                <Typography component="li">
                  <NavLink onClick={()=>setOpenMenu(false)} className={styles.list_inner} to={"/all-employers"}>
                    Who's Hiring
                  </NavLink>
                </Typography>
                <Typography component="li">
                  <NavLink onClick={()=>setOpenMenu(false)} className={styles.list_inner} to={"/job-search"}>
                    Career Center
                  </NavLink>
                </Typography>
                {/* <Typography component="li">
                  <NavLink className={styles.list_inner} to={"/about-us"}>
                    About us
                  </NavLink>
                </Typography>  */}
                <Typography component="li">
                  <NavLink onClick={()=>setOpenMenu(false)} className={styles.list_inner} to={"/talent-toolkit"}>
                    Talent Toolkit
                  </NavLink>
                </Typography>
              </Typography>
              {!isLoggedIn && accessToken === null ? (
                <Box className={styles.cta}>
                  <CustomButton
                    label={"Log in"}
                    onClick={() => history.push("/login")}
                    classType={"btn-outline-primary"}
                  />
                  <CustomButton
                    label={"Register"}
                    onClick={() => handleOpen()}
                    classType={"btn-primary"}
                  />
                </Box>
              ) : (
                <Box className={styles.cta}>
                  <NavLink  onClick={()=>setOpenMenu(false)} className={styles.list_inner} to={"/login"}>
                    My Account
                  </NavLink>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>}
      {open && (
        <RegisterOnBoardModal
          open={open}
          handleClose={handleClose}
          history={history}
        />
      )}
    </>
  );
};

export default LoginHeader;
