import * as React from "react";
import { useSelector } from "react-redux";
import {Route, Redirect } from "react-router-dom";

export const PublicRoutes = ({ component:Comp, ...rest }) => {
  const {isLoggedIn,userType,userInfo}= useSelector((state) => state.auth);
  const [isAuthenticated,setAuthenticated]=React.useState(localStorage.getItem("token"));

  React.useEffect(()=>{
    setAuthenticated(localStorage.getItem("token"));
  },[isLoggedIn])

  const username=userInfo?.userInfo?.username
  



  return (
    <Route
      render={({ location }) =>
        !(isLoggedIn || isAuthenticated!==null) ? (
          <Comp/>
        ) : (
          <Redirect  to={{  pathname: userType==='student'? `/student-profile/${username}`:`/employer/${username}`}}
        /> 
        )
      }
    />
  );
};
