import * as React from "react";
import { Box, Typography} from "@mui/material";
import "../sass/landingProtofolioCard.scss"


const LandingCard2 = (props) => {
     return (
          <Box className={`card_rev ${props.classes}`} >
               {/* {props.logo && <img src={props.logo} alt={props.name} />} */}
               <Typography className="review" >{props.data}</Typography>
               <Typography component="div" className="reviews" >{"-"+props.name}</Typography>
          </Box>

     )
}
export default LandingCard2;