
export const JobLevel = [
  { id: 1, name: "Co-op", value: "Co-op" },
  { id: 2, name: "New Graduate", value: "New Graduate" },
  { id: 3, name: "Experienced", value: "Experienced" },
];

export const JobTypes = [
  { id: 1, name: "Co-op", value: "co-op" },
  { id: 2, name: "Permanent", value: "Permanent" },
  { id: 1, name: "Contract", value: "Contract" },
];

export const Years = [
  { id: 1, name: "Year 1", value: "Year 1" },
  { id: 2, name: "Year 2", value: "Year 2" },
  { id: 3, name: "Year 3", value: "Year 3" },

  { id: 5, name: "Year 4", value: "Year 4" },

  { id: 6, name: "Year 5", value: "Year 5" },
  { id: 7, name: "Open To All Years", value: "Open To All Years" },
];



export const NumberOfEmployes = [

  {
    id: 1,
    from: "1",
    upto: "500",
    name: "1 - 500",
    value: "500",
  },
  {
    id: 3,
    from: "20,000",
    upto: "30,000",
    name: "500 - 1000",
    value: "1000",
  },
  {
    id: 4,
    from: "30,000",
    upto: "40,000",
    name: "1000 - 10,000",
    value: "10000",
  },

  { id: 10, from: "10,0000", upto: "", name: " Over 10,0000", value: "10,0000" },
];

export const SalaryRange = [
  {
    id: 1,
    from: "10,000",
    upto: "20000",
    name: "$10,000 - $20,000",
    value: "1",
  },
  {
    id: 1,
    from: "20,000",
    upto: "30,000",
    name: "$20,000 - $30,000",
    value: "1",
  },
  {
    id: 1,
    from: "30,000",
    upto: "40,000",
    name: "$30,000 - $40,000",
    value: "1",
  },
  {
    id: 1,
    from: "40,000",
    upto: "50,000",
    name: "$40,000 - $50,000",
    value: "1",
  },
  {
    id: 1,
    from: "50,000",
    upto: "60,000",
    name: "$50,000 - $60,000",
    value: "1",
  },
  {
    id: 1,
    from: "60,000",
    upto: "70,000",
    name: "$60,000 - $70,000",
    value: "1",
  },
  {
    id: 7,
    from: "70,000",
    upto: "80,000",
    name: "$70,000 - $80,000",
    value: "7",
  },
  {
    id: 8,
    from: "80,000",
    upto: "90,000",
    name: "$80,000 - $90,000",
    value: "8",
  },
  {
    id: 9,
    from: "90,000",
    upto: "10,000",
    name: "$90,000 - $100,000",
    value: "9",
  },
  { id: 10, from: "100,000", upto: "", name: "$100,000+", value: "10" },
];

export const SchoolTableHeader = [
  { id: 1, name: "School", asdcOrder: true, type: "text", value: 'name', isSortAble: true },
  { id: 11, name: "Faculty", asdcOrder: true, type: "text", value: 'facultyName', isSortAble: false },
  { id: 12, name: "Overall", asdcOrder: true, type: "number", value: 'overall', isSortAble: true },

  { id: 2, name: "Location", asdcOrder: true, type: "text", value: 'location', isSortAble: true },

  { id: 3, name: "Culture", asdcOrder: true, type: "number", value: 'culture', isSortAble: true },

  { id: 4, name: "Support", asdcOrder: true, type: "number", value: 'support', isSortAble: true },

  { id: 5, name: "Opportunity", asdcOrder: true, type: "number", value: 'oppertunities', isSortAble: true },
  { id: 6, name: "Affordbility", asdcOrder: true, type: "number", value: 'affordability', isSortAble: true },
];


// export const FilePath = "https://gettingahead.education/backend/"
export const FilePath = process.env.REACT_APP_FILE_URL
export const FilePathEmp = "https://gettingahead.education/backend/"


export const validEmail = [
  { id: 1, emailFormat: '@viu.ca' },
  { id: 2, emailFormat: '@vcu.ca' }

]

export const Roles = {
  Student: 'student',
  Employer: 'employer'
}

export const CampusCategoryList = [
  {
    id: 1,
    categoryName: "Talent Experience and Culture",
    rating: 0,
    description: "Overall talent experience at campus including security and culture",
    name: 'experience'
  },
  {
    id: 2,
    categoryName: "Campus services",
    rating: 0,
    description: "Quality of services available on campus such as food, recreation, personal and medical services",
    name: 'service'

  },
  {
    id: 3,
    categoryName: "Campus facilities",
    rating: 0,
    description: "Quality of facilities, technology, access to parking",
    name: 'facility'

  },
];

export const FacultyCategoryList = [
  {
    id: 1,
    categoryName: "Clubs and teams",
    rating: 0,
    description: "Access to and culture of teams and clubs",
    name: 'team'
  },
  {
    id: 2,
    categoryName: "Networking and Career Opportunities",
    rating: 0,
    description: "Access to Alumni, career services, support and opportunities",
    name: 'opportunities',

  },
  {
    id: 3,
    categoryName: "Quality of Education",
    rating: 0,
    description: "Overall quality of education and skillset development ",
    name: 'education'
  },
];

export const ProgramCategoryList = [
  {
    id: 1,
    categoryName: "Career Support and Outcomes",
    rating: 0,
    description: "Access to relevant career opportunities, employers and program specific career opportunities",
    name: 'career'
  },
  {
    id: 2,
    categoryName: "Access to Personalized Support ",
    rating: 0,
    description: "Access to personalized support for career, academic or health related outcomes",
    name: 'support'

  },
  {
    id: 3,
    categoryName: "Cost of living",
    rating: 0,
    description: "Cost of housing, food, recreation, services, transportation in location ",
    name: 'livingcost'

  },
  {
    id: 4,
    categoryName: "School fees",
    rating: 0,
    description: "Cost of fees relative services, support and outcomes",
    name: 'schoolfee'

  },
];

export const TalentTableHeader = [
  { id: 1, name: 'Username', value: 'username' },
  { id: 6, name: 'Email', value: 'email' },

  { id: 2, name: 'School Name', value: 'schoolname' },
  { id: 3, name: 'Faculty', value: 'faculty' },
  { id: 4, name: 'Program Year', value: 'year' },
  { id: 5, name: 'GPA', value: 'gpa' },
]


export const TalentTableData = [
  { id: 1, username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 2, username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 3, username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 4, username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 5, username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 6, username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },

];

export const JobTableData = [
  { id: 1, job:'Job 1', location:'1',username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 2, job:'Job 2',location:'1',username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 3, job:'Job 3', location:'1',username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 4, job:'Job 4', location:'1',username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 5, job:'Job 1', location:'1',username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },
  { id: 6, job:'Job 1',location:'1',username: 'Cathenna Phillips', schoolName: 'Vancouver Island University', faculty: 'Social Science', year: 'May 2019 - Apr 2022', gpa: 10 },

];

export const JobTableHeader=[

  {id:1,name:"Job Title"},
  {id:2,name:"Total Applicants"},
  {id:5,name:"New Applicants"},
  {id:3,name:"Shortlisted"},
  {id:4,name:"Hired"},
  
  {id:6,name:"Not Suitable"},
  {id:7,name:"Posting Date"},
  {id:8,name:"Status"},
  {id:9,name:"Action"},
]

export const JobStatusMenu=[
  {id:1,name:'Active',value:'active',color:'#00FF00'},
  {id:1,name:'Pause',value:'inactive',color:"#FFFF00"},
  {id:1,name:'Closed',value:'closed',color:"#FF0000"},
]

export const jobClosedReasons=[
  {
    id:1,name:"Hired",value:"hired"
  }, {
    id:2,name:"Position No Longer Available",value:"no_longer_needed"
  }, {
    id:3,name:"Located a Valid Candidate Elsewhere",value:"not_valid_candidate"
  },
]

export const NoRecordTitle = "There is no records";
export const NoJobTitle = "There are no jobs currently available that meet your search criteria";


