import axios from "axios";
import auth0 from "auth0-js";

//import { AxiosInstance } from "../axios/AxiosProvider";
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_APIURL = process.env.REACT_APP_AUTH0_APIURL;
const AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID;
const AUTH0_CLIENT_SECRET = process.env.REACT_APP_AUTH0_CLIENT_SECRET;
const AUTH0_CALLBACK_URL = process.env.REACT_APP_AUTH0_CALLBACK_URL;
// const AUTH0_CALLBACK_URL ="http://gettingaheadnew.oursitedemo.com/callback"


export const AxiosInstance = axios.create({
  baseURL: AUTH0_DOMAIN,
  timeout: 235000,
  headers: {
    "Content-Type": "application/json",
    //"Access-Control-Allow-Origin": "*",
  },
});

const setCallBackUrl = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    return "http://localhost:3000/callback"
    
  else
    return AUTH0_CALLBACK_URL

}

export const Auth0Api = {
  async createUser(params) {
    let api_params = [];
    api_params["client_id"] = AUTH0_CLIENTID;
    api_params["client_secret"] = AUTH0_CLIENT_SECRET;

    api_params["connection"] = "Username-Password-Authentication";
    let new_api_params = { ...params, ...api_params };

    try {
      const response = await AxiosInstance.post("register/login", {
        ...new_api_params,
      });

      if (response.status === 200) {
        
        return {
          success: "true",
          message: "Registered successfully!",
          data: response,
        };
      }
    } catch (e) {
      
      let error_response = e.response.data;
      let error_message = "";

      if (error_response?.message) {
        error_message = error_response.message;
      } else {
        error_message = error_response.description;
      }

      return { error: "true", message: error_message, data: error_response };
    }
  },

  async getAuthenticationFromAuth0(params) {
    

    return new Promise(function (resolve, reject) {
      var webAuth = new auth0.WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENTID,
        // redirect_uri: "localhost:3000/login",
      });
      webAuth.signup(
        {
          connection: "Username-Password-Authentication",
          ...params,
        },
        // 
        function (err, success) {
          if (err) {
            reject(err);
          } else {
            
            resolve(success);
          }
        }
      );
    });
  },

  doLoginAtAuth0(params) {
   
    


    return new Promise(function (resolve, reject) {
      var webAuth = new auth0.WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENTID,
        credential_type: "http://auth0.com/oauth/grant-type/password-realm",
        responseType: "token",
        scopes: 'openid',
        SameSite:'None',
        redirectUri: setCallBackUrl(),


      });
      webAuth.login(
        {
          realm: "Username-Password-Authentication",
          username: params.username,
          password: params.password,
        },
        // 
        function (err, success) {
          if (err) {
            reject(err);
          } else {
            
            resolve(success);
          }
        }
      );
    });
  },

  resetPasswordMail(email) {
    


    return new Promise(function (resolve, reject) {
      var webAuth = new auth0.WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENTID,

      });
      webAuth.changePassword(
        {
          connection: "Username-Password-Authentication",
          email: email
        },
        // 
        function (err, success) {
          if (err) {
            reject(err);
          } else {
            
            resolve(success);
          }
        }
      );
    });
  },

  logoutFromAuth0() {
    

    return new Promise(function (resolve, reject) {
      var webAuth = new auth0.WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENTID,



      });
      webAuth.logout(
        {
          clientID: AUTH0_CLIENTID,

        },
        // 
        function (err, success) {
          if (err) {
            reject(err);
          } else {
            
            resolve(success);
          }
        }
      );
    });
  },

  async getUserInfo(params) {
    
    


    return new Promise(function (resolve, reject) {
      var webAuth = new auth0.WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENTID,
      });
      webAuth.parseHash(params, function (err, authResult) {
        if (err) {
          return err
        }
        webAuth.client.userInfo(
          authResult.accessToken,
          function (err, success) {
            if (err) {
              reject(err);
            } else {
              
              resolve(success);
            }
          }
        );
      });
    });
  },

  async deleteUserFromAuth0(id) {

    try {
      var webAuth = new auth0.WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENTID,
        scopes: 'delete:current_user_metadata',
       

      
      });
      const response = await axios.delete(`${AUTH0_APIURL}users/${id}`);
      if (response.status === 200) return response.data;
    } catch (error) {
      
      return error;
    }
  },



};
