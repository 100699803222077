import Loader from "components/common/Loader";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setLoggedIn,
  setLoggedInToken,
  setUserInfo,
  setUserType,
} from "slices/auth-slice";
import { CommonAPIS } from "utils/API/common-apis";
import { StudentAPIS } from "utils/API/student-apis";

const CallBackPage = () => {
  const history = useHistory();

  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  const location = useLocation();


  useEffect(() => {
    if (location.hash) {
      if(location?.hash?.includes("access_token"))
      getUserInfo();
      else {
        toast.error("Your browser does not allowed cookie please enable it to use this site");
        setLoader(false)
        history.push("/login")
      }
    }
   
  }, [location]);

  const getUserInfo = async () => {
    setLoader(true);
    try {
      // const response = await StudentAPIS.fetchAuthUserDetails(token);
      const response = await StudentAPIS.fetchAuthUserDetailsFromAuth0(
        location
      );

      if (response?.success) {
        const accessToken = response.data;
        getUserProfile(accessToken);
      }
      else if (!response?.email_verified) {
        toast.error("Your email is not verified please verify email first.");
        history.push("/login")

      } else {
        toast.error(response.message, {
          position: "top-center",
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const getUserProfile = async (token) => {
    

    try {
      const response = await CommonAPIS.fetchUserDetails(token);
      if (response.success) {
        const data = response.data;
        return setupUser(token, data);
      } else {
        console.error(response.error);
        toast.error(response?.message);
      }
    } catch (e) {
      console.error(e);
      toast.error("Network Error");
      setLoader(false);
    }
  };

  const setupUser = (token, data) => {

    let userId = data[0]?._id;
    dispatch(setUserInfo(data[0]));
    dispatch(setUserType(data[0]?.role))
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('username');
    localStorage.setItem('uniqueId' , data[0]?._id );

    localStorage.setItem("token", token);
    localStorage.setItem("userType", data[0]?.role);
    localStorage.setItem("username",  data[0]?.userInfo.username);

    dispatch(setLoggedInToken(token));
    dispatch(setLoggedIn(true));
    getDefaultRoute(data[0]);
  };

  const getDefaultRoute = (user) => {
     if (localStorage.getItem("postLink")) {
      let url = localStorage.getItem("postLink");
      localStorage.removeItem("postLink")
      return history.replace(url)
    }
    else if(localStorage.getItem("jobLink")) {
      let url = localStorage.getItem("jobLink");
      localStorage.removeItem("jobLink")
      return history.replace(url)
    }
    else if (user?.role === "employer") {
      return history.replace(`/employer/${user?.userInfo?.username}`);
    } else {
      return history.replace(`/student-profile/${user?.userInfo?.username}`);
    }
  };

  return <div className="Profile">{loader && <Loader />}</div>;
};
export default CallBackPage;
