import * as React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import StudentRegister from "../pages/student-register";
import CallBackPage from "pages/CallbackPage";
import { ProtectedRoutes } from "utils/ProtectedRoutes";
import { PublicRoutes } from "utils/PublicRoutes";
import { useSelector } from "react-redux";
import { lazy } from "react";
import LoginHeader from "components/loginHeader";
import landing from "../pages/landing/landing"
import EmployerLanding from "pages/employer-landing";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import EducatorLanding from "pages/educator-landing";
import ErrorPage from "components/errorPage/ErrorPage";

const Login = lazy(() => import('../pages/login'));
const AdminPanelLogin = lazy(() => import('pages/adminPanelLogin'));
const Terms = lazy(() => import('pages/terms'));
const PrivacyPolicy = lazy(() => import('pages/privacy-policy'));
const EmployerRegister = lazy(() => import('../pages/employer-register'));
const Header = lazy(() => import('../components/header'));
const Footer = lazy(() => import('../components/footer'));
const AddJob = lazy(() => import('../pages/employer/add-job'));
const EditJob = lazy(() => import('../pages/employer/edit-job'));
const EmployerJobs = lazy(() => import('../pages/employer/EmployerJobs/employer-jobs'));
const EditEmployerInfo = lazy(() => import('pages/employer/edit-employer-info'));
const AllEmployers = lazy(() => import('pages/employer/all-employers'));
const Jobs = lazy(() => import('pages/jobs'));
const TalentList = lazy(() => import('pages/talent/TalentList'));
const TopRated = lazy(() => import('pages/employer/top-rated'));
const UniversityReview = lazy(() => import('pages/university-review'));
const ForgotPassword = lazy(() => import('pages/forgot-password'));
const EmployerBenefit = lazy(() => import('pages/employer/employerBenefits'));
const AdminPanelHome = lazy(() => import('pages/adminPanelHome'));
const School = lazy(() => import('pages/school'));
const SingleFeed = lazy(() => import('pages/SingleFeed'));
const JobDescription = lazy(() => import('pages/job-description'));
const JobsRecomend = lazy(() => import('pages/jobs-recomended'));
const StudentProfileEdit = lazy(() => import('../pages/student-profile-edit'));
const CompareSchool = lazy(() => import('pages/school/compare-school'));
const Feed = lazy(() => import('../pages/feed'));
const EmployerDetails = lazy(() => import('../pages/employer/EmployerJobs/employer-details'));
const StudentProfile = lazy(() => import('../pages/student-profile'));
const AboutUs = lazy(() => import('../pages/aboutUs'));
const TalentToolkit = lazy(()=>import("../pages/talentToolkit/index")) 





const Routes = () => {
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY; // OUR_TRACKING_ID

  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  ReactGA.initialize(TRACKING_ID);

  // const isLoggedIn = true
  useEffect(() => {
    const initializeGA = async () => {
      ReactGA.initialize(TRACKING_ID);
    };

    const handleLoad = () => {
      initializeGA();
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  React.useEffect(() => {
    let path = location.pathname
    ReactGA.send({ hitType: "pageview", page: { path }, title: "Getting Ahead" });
  }, [location.pathname])


  const onbordingPages = [
    "/login",
    "/student-register",
    "/employer-register",
    "/terms",
    "/privacy",
    "/forgot-password",
    '/',
    "/admin",
    "/admin-panel",
    , "/about-us",
    "/employers",
    "/educators"
  ];

  const hideHeader = onbordingPages.includes(location.pathname) ? true : false;

  const nonLoggedInPages = ["/", "/callback", '/employer-benefits',
    "/for-employers", "/blog", "/contact", "/founders", "/community", "/company", "/employers", "/about-us" ,"/talent-toolkit", "/login", "/student-register", "/employer-register", "/educators"];

  const specialPages = ["/all-employers","/job-search"]
  const pagesHideHeader = ["/login", "/student-register", "/employer-register"]

  const specialPagesInclude = specialPages.includes(location.pathname);

  const showNonLoggedInHeader = nonLoggedInPages.includes(location.pathname);

  return (
    <>
      {(showNonLoggedInHeader) ?
       <LoginHeader topBarBg="#2196F3" hideHeader={pagesHideHeader.includes(location.pathname)} /> : (specialPagesInclude && !isLoggedIn) ? <LoginHeader topBarBg="#2196F3" hideHeader={pagesHideHeader.includes(location.pathname)} /> : ""}
      {!showNonLoggedInHeader && !hideHeader ?
        (isLoggedIn && <Header />)
        : ""}
      <Switch>

        <PublicRoutes component={CallBackPage} path="/callback" />
        <PublicRoutes component={StudentRegister} path="/student-register" />
        <ProtectedRoutes component={StudentProfile} path="/student-profile/:id" canAccessOnly={''} />
        <PublicRoutes component={Login} path="/login" />
        <PublicRoutes component={EmployerRegister} path="/employer-register" />
        <ProtectedRoutes component={Feed} path="/feed" canAccessOnly={'student'} />
        <ProtectedRoutes component={CompareSchool} path="/compare-school" canAccessOnly={''} />
        <ProtectedRoutes component={StudentProfileEdit} path="/student-profile-edit" canAccessOnly={'student'} />
        <ProtectedRoutes component={AdminPanelHome} path="/admin-panel" canAccessOnly={'admin'} />
        <Route component={JobsRecomend} path="/job-search" />
        <ProtectedRoutes component={SingleFeed} path="/feed-details/:postId" canAccessOnly={''} />
        <ProtectedRoutes component={JobDescription} path="/job-description/:id" canAccessOnly={''} />
        <ProtectedRoutes component={School} path="/school/:id" canAccessOnly={''} />
        <PublicRoutes component={EmployerBenefit} path="/employer-benefits" />
        <PublicRoutes component={EmployerBenefit} path="/for-employers" />
        <PublicRoutes component={EmployerBenefit} path="/contact" />
        <PublicRoutes component={EmployerBenefit} path="/founders" />
        <PublicRoutes component={EmployerBenefit} path="/community" />
        <PublicRoutes component={EmployerBenefit} path="/company" />
        <PublicRoutes component={ForgotPassword} path="/forgot-password" />
        <Route component={AllEmployers} path="/all-employers" />
        <ProtectedRoutes component={AddJob} path="/add-job" canAccessOnly={'employer'} />
        <ProtectedRoutes component={EditJob} path="/edit-employer-job/:jobId" canAccessOnly={''} />
        <ProtectedRoutes component={EditEmployerInfo} path="/edit-employer-info" canAccessOnly={'employer'} />
        <ProtectedRoutes component={EmployerDetails} path="/employer/:id" canAccessOnly={''} />
        <ProtectedRoutes component={Jobs} path="/jobs" canAccessOnly={''} />
        <ProtectedRoutes component={EmployerJobs} path="/employer-jobs/:id" canAccessOnly={'employer'} />
        <ProtectedRoutes component={UniversityReview} path="/university-review/:schoolId" canAccessOnly={''} />
        <ProtectedRoutes component={UniversityReview} path="/faculty-review/:schoolId/:facultyId" canAccessOnly={''} />
        <ProtectedRoutes component={TopRated} path="/top-rated" canAccessOnly={''} />
        <ProtectedRoutes component={TalentList} path="/talents" canAccessOnly={''} />
        <ProtectedRoutes component={TalentList} path="/applicants/:jobId" canAccessOnly={''} />

        <Route component={landing} exact path="/" />
        <Route component={EmployerLanding} exact path="/employers" />
        <Route component={EducatorLanding} exact path="/educators" />
        
        <Route component={AboutUs}  path="/about-us" />
        <Route component={Terms} path="/terms" />
        <Route component={PrivacyPolicy} path="/privacy" />
        <Route component={AdminPanelLogin} path="/admin" />   
        <Route component={TalentToolkit}  path="/talent-toolkit" />
        <Route component={ErrorPage} />
      </Switch>
      {!hideHeader ? <Footer /> : ""}
    </>
  );
};

export default Routes;

