import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import InputBox from "../components/common/InputBox";
import { Typography, Grid, Box, Link } from "@mui/material";
import Logo from "../assets/svg-icon/logo.svg";
import CustomButton from "../components/common/CustomButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "../utils/rules";
import TermsPopup from "components/Popups/TermsPopup";
import { toast } from "react-toastify";
import { StudentAPIS } from "utils/API/student-apis";
import { CommonAPIS } from "utils/API/common-apis";
import { HelmetComponent } from "components/common/HelmetComponent";
import "../sass/register.scss";



const schema = yup.object().shape({
  firstname: yup.string().required().minStringLength(3).trim(),
  lastname: yup.string().required().minStringLength(3).trim(),
  username: yup
    .string()
    .required()
    .test("len", "Must be minimum 3 characters", (val) => val.length > 2)
    .trim(),
  email: yup.string().required().email(),
  password: yup.string().required().password(),
  confirmpassword: yup
    .string()
    .required()
    .test(
      "passwords-match",
      "Password and Confirm password does not match.",
      function (value) {
        return this.parent.password === value;
      }
    ),
});

const seoData = {
  canonical: {
    "href": "https://gettingahead.education/student-register"
  },
  ogTitle: {
    "content": "Find jobs, advice and school reviews | Jobs | Getting Ahead"
  },
  ogDescription: {
    "content": "Start your career. Guidance, resources and find jobs."
  },
  ogImage: {
    "content": "https://gettingahead.education/static/media/logo.f6ccefcc.svg",
    "type": "image/svg"
  }
}

 


const StudentRegister = () => {

  const [btnLoader, setBtnLoader] = useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const validateUserNameAndEmail = async (data) => {
    try {
      setBtnLoader(true);
      let param = {
        username: data.username,
        email: data.email,
      };
      const response = await CommonAPIS.verifyUserName(param);

      if (response.success) {
        registerUser(data);
      } else {
        toast.error(response.message, {
          position: "top-center",
        });
        setBtnLoader(false);
      }
    } catch (e) {
      console.error(e);
      setBtnLoader(false);
    }
  };

  const registerUser = async (data) => {
    try {
      setBtnLoader(true);

      const response = await StudentAPIS.createStudent(data);

      if (response.success) {
        setBtnLoader(false);

        toast.success(response.message, {
          autoClose: 11000
        });
        history.push("/login");
      } else {
        toast.error(response?.message || response.description);
      }
    } catch (e) {
      console.error(e);
      toast.error(e.description);
    } finally {
      setBtnLoader(false);
    }
  };

  const onSubmit = (data) => {
    validateUserNameAndEmail(data);
  };

  const onErrors = (errors) => console.error(errors);

  const history = useHistory();
  const checkStatus = () => {
    let sttaus = localStorage.getItem("utc");
    if (sttaus === null || sttaus === undefined) return true;
    else return false;
  };

  const onAcceptClick = () => {
    localStorage.setItem("utc", false);
    handleTermsModal(false);
  };
  const [showTermsModal, handleTermsModal] = React.useState(checkStatus());

  return (
    <>
      <HelmetComponent {...seoData} />
      <div className="Register">
        <div className="container">
          <Box className="logo">
            <Link to="/" onClick={() => history.push("/")}>
              <img loading="lazy" src={Logo} alt="Getting Ahead" />
            </Link>
          </Box>
          <Grid container justifyContent="flex-end">
            <Grid item sm={6} lg={5}>
              <Typography component="h1" className="Register_Title">
                Talent Registration
              </Typography>
              <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <InputBox
                  label={"First name"}
                  type={"text"}
                  name="firstname"
                  register={register}
                  required={true}
                  error={errors.firstname?.message}
                />

                <InputBox
                  label={"Last name"}
                  type={"text"}
                  name="lastname"
                  register={register}
                  required={true}
                  error={errors.lastname?.message}
                />
                <InputBox
                  label={"Username"}
                  type={"text"}
                  name="username"
                  register={register}
                  required={true}
                  error={errors.username?.message}
                />
                <InputBox
                  label={"Personal Email Address"}
                  type={"email"}
                  name="email"
                  register={register}
                  required={true}
                  error={errors.email?.message}
                />
                <InputBox
                  label={"Password"}
                  type={"password"}
                  name="password"
                  register={register}
                  required={true}
                  error={errors.password?.message}
                />
                <InputBox
                  label={"Confirm password"}
                  type={"password"}
                  name="confirmpassword"
                  register={register}
                  required={true}
                  error={errors.confirmpassword?.message}
                />
                <Box className="form-group">
                  <CustomButton
                    label={"Create account"}
                    classType={"btn-primary"}
                    buttonType="submit"
                    isLoading={btnLoader}
                  />
                </Box>
                <Box className="form-group">
                  <Typography component="p">
                    Already have an account?{" "}
                    <Link onClick={() => history.push("/login")}>Log in</Link>
                  </Typography>
                </Box>
                <Box className="form-group" mb="0">
                  <Typography component="p" className="terms">
                    By confirming the sign up on our website, you automatically
                    agree to the{" "}
                    <NavLink to={"/terms"} target="_blank">
                      Terms of Use
                    </NavLink>
                    <span> &</span>
                    <NavLink to={"/privacy"} target="_blank">
                      Privacy policy
                    </NavLink>
                  </Typography>
                </Box>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
      <TermsPopup
        open={showTermsModal}
        handleClosePopup={() => onAcceptClick()}
      />

    </>
  );
};
export default StudentRegister;
