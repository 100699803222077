import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const Utils = {
  commonValidationMsg: "This field is required.",
};

export const formatDate=(date)=> {
  return dayjs(date).format('DD-MM-YYYY')
}
export const formatDateToMonthYear=(date)=> {
  return dayjs(date).format('MMM YYYY')
}

export const formatDateWithTime=(date)=>{
  return dayjs(date).format('DD-MM-YYYY, h:mm a');
}

export const getAvgReview=(data)=> {
  let sum= data.reduce((a, b) => a + b.reviewCount, 0);
 let length=data?.length*10;
  let avg=sum/length;
  return avg

 }
 export const calculateAvgRating = (data,key) => {
  let sum = data.reduce((a, b) => a + b[key], 0);
  let length = data.length
  let avgData = sum / length
  
  return Number.isNaN(avgData)?0:avgData
}

 export const formatDateInLocale=(date) => {
   const formattedDate = dayjs(date)
   .tz('Asia/Kolkata') // Set the timezone to India Standard Time (GMT+0530)
   .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
  return new Date(formattedDate) 
 }

 export const setDateOF15=(date)=> {
 let newDate= new Date(date).setDate(15);
 return dayjs(newDate).format("YYYY-MM-DD")
 }




