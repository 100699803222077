import React from 'react';
import { Link } from 'react-router-dom';
import classes from './errorPage.module.scss';
import LoginHeader from 'components/loginHeader';
import { useSelector } from 'react-redux';
import Header from 'components/header';

const ErrorPage = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  console.log('cdcd', isLoggedIn) 
  return (
    <>
    {isLoggedIn ? null : <LoginHeader topBarBg="#2196F3" hideHeader={isLoggedIn} /> } 
      <div className={classes['not-found-container']}>
        <h1 className={classes['not-found-title']}>404</h1>
        <p className={classes['not-found-message']}>Oops! The page you're looking for doesn't exist.</p>
        <Link to="/" className={classes['not-found-link']}>Go back to the homepage</Link>
      </div>
    </>
  );
};

export default ErrorPage;

