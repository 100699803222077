import ScrollToTop from "components/common/ScrollToTop";
import * as React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Routes from "./routes/routes";
import "./utils/rules";
import { useDispatch } from "react-redux";
import { CommonAPIS } from "utils/API/common-apis";
import { setLoggedIn, setLoggedInToken, setUserInfo, setUserType } from "slices/auth-slice";
import { setDegreeList, setFacultyList, setJobLevels, setJobTypes, setLocationList, setPayPeriodList, setSalaryRange, setSchoolData, setSchoolList, setTagList, setTargetYears ,setIndustries} from "slices/ui-slice";
import Loader from "components/common/Loader";
import { Suspense } from "react";
import { HelmetProvider } from 'react-helmet-async';
import "./App.scss"


function App() {
  const { accessToken, isLoggedIn } = useSelector((state) => state.auth);
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  var active = document.querySelector("#root");
  active.classList.remove("center-div")

  React.useEffect(() => {
    // Dynamically load the CSS file

    import("react-toastify/dist/ReactToastify.css")
    import('react-multi-carousel/lib/styles.css')
    import('react-datepicker/dist/react-datepicker.css')

      .then(() => {
        console.log("loaded successfully");

      })
      .catch((error) => {
        console.error("Failed to load CSS:", error);
      });
      return () => {

    }
  }, []);



  React.useEffect(() => {
    if (!isLoggedIn) {
      let token = localStorage.getItem("token");
      if (token) {
        getUserProfile(token).then(() => setLoaded(true))
      } else {
        setLoaded(true)
      }
    }
  }, [isLoggedIn]);


  React.useEffect(() => { 

    if (isLoggedIn && accessToken)
      setupApplication(accessToken)
  }, [isLoggedIn, accessToken,]);
 
  const getUserProfile = async (token) => {

    try {
      const response = await CommonAPIS.fetchUserDetails(token);
      if (response.success) {
        const data = response.data;
        console.log({data})
        localStorage.setItem('uniqueId' , data[0]?._id );
        return setupUser(token, data);
      } else {
        console.error(response.error);
        localStorage.removeItem('token');
        localStorage.removeItem('userType');
        
        toast.error('Your session expire please login again');
        return history.push("/login")
      }
    } catch (e) {
      console.error(e);
      // toast.error("Network Error");
    }
  };

  const setupUser = (token, data) => {
    dispatch(setUserType(data[0]?.role))
    dispatch(setUserInfo(data[0]));
    localStorage.removeItem('token');
    localStorage.removeItem('username');

    localStorage.removeItem('userType');
    localStorage.setItem("token", token);
    localStorage.setItem("username", data[0]?.userInfo.username);
    localStorage.setItem("userType", data[0]?.role);

    dispatch(setLoggedInToken(token));
    dispatch(setLoggedIn(true));
    setupApplication(token)
  };



  const setupApplication = async (token) => {
    try {
      const [
        tagsList,
        locationList,
        jobLevels,
        jobTypes,
        salaryRange,
        payPeriodList,
        schoolList,
        targetYears,
        facultyList,
        degreeList,
        industries
      ] = await Promise.all([
        CommonAPIS.fetchTagList(accessToken),
        CommonAPIS.fetchLocationList(accessToken),
        CommonAPIS.fetchJobLevels(accessToken),
        CommonAPIS.fetchJobTypes(accessToken),
        CommonAPIS.fetchSalaryRange(accessToken),
        CommonAPIS.fetchPayPeriods(accessToken),
        CommonAPIS.fetchSchoolList(token),
        CommonAPIS.fetchTargetYears(accessToken),
        CommonAPIS.fetchFaculties(accessToken),
        CommonAPIS.fetchDegrees(accessToken), 
        CommonAPIS.getIndustries(accessToken)
      ]);

      if (tagsList.success) {
        const data = tagsList.data;

        dispatch(setTagList([...data]))

      }
      if (locationList.success) {
        const data = locationList.data;
        let modifiedData = data.map((me) => {
          return {
            _id: me?._id,
            name: me.name + "," + " " + me.locationCode,
          };
        });
        dispatch(setLocationList([...modifiedData]))

      }

      if (jobLevels.success) {
        const data = jobLevels.data;
        dispatch(setJobLevels([...data]));
      }

      if (jobTypes.success) {
        const data = jobTypes.data;
        dispatch(setJobTypes([...data]));

      }
      if (salaryRange.success) {
        const data = salaryRange.data;
        dispatch(setSalaryRange([...data]));


      }

      if (payPeriodList.success) {
        const data = payPeriodList.data;
        dispatch(setPayPeriodList([...data]));

      }

      if (schoolList.success) {
        const data = schoolList.data;


        let modifiedSchools = data.map((me, index) => {
          return {
            name: me.schoolInfo.name,
            _id: me._id,
          };
        });

        dispatch(setSchoolList([...modifiedSchools]));
        dispatch(setSchoolData([...data]));
      }

      if (targetYears.success) {
        const data = targetYears.data;
        dispatch(setTargetYears([...data]));
      }
      if (facultyList.success) {
        const data = facultyList.data;
        dispatch(setFacultyList([...data]));
      }

      if (degreeList.success) {
        const data = degreeList.data
        dispatch(setDegreeList([...data]));
      } 

      if(industries.success){ 
        const data =  industries.data
        dispatch(setIndustries([...data]))
      }


    } catch (e) {
      console.error(e);
    }

  };

  // if(!loaded) return <Loader/>

  return (
    <div className="App">
      <HelmetProvider>
        <Router>
          <ScrollToTop />

          <Suspense fallback={<Loader />}>


            <Routes />
          </Suspense>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;

