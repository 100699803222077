import * as React from "react";
import "./Popups.scss";
import { Typography, Box, Modal } from "@mui/material";
import CustomButton from "../common/CustomButton";
import Terms from "pages/terms";

const TermsPopup = ({ open, handleClosePopup }) => {

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="AddProfileModalPopUp"
      disableBackdropClick
    >
      <Box className="AddProfileModalPopUp_Box">

        <Typography component="div" className="terms-popup" >
          <Terms showAs="modal" addClass="aspopup" />
        </Typography>

        <Typography component="div" className="termsacceptbtn" >
          <CustomButton
            label={"Click to Accept"}
            classType={"btn-primary"}
            onClick={handleClosePopup}
          />
        </Typography>
      </Box>
    </Modal>
  );
};
export default TermsPopup;
