import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./loader.scss";

const Loader = ({ type = "fullScreen" }) => {
  return (
    <>
      {type === "fullScreen" ? (
        <Box className="loader-div">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="small-loader-div">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default Loader;
