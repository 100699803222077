import * as React from "react";
import "../sass/terms.scss";
import { Typography, Box, Zoom, Grid } from "@mui/material";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { toast } from "react-toastify";
import { CommonAPIS } from "utils/API/common-apis";
import Loader from "components/common/Loader";
import { HelmetComponent } from "components/common/HelmetComponent";

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };




  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 20, right: 20 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Terms = (props) => {


  const [loader, setLoader] = React.useState(true)
  const [description, setDiscription] = React.useState('')


  React.useEffect(() => {
    getTerms()

  }, [])




  const getTerms = async () => {


    try {

      const response = await CommonAPIS.getTermsContent();
      if (response.success) {
        const data = response.data
        // setFeedData([...data])
        setDiscription(data.description)
      }
      else
        toast.error(response.message)
    }
    catch (e) {
      console.error(e);
      toast.error('Network Error')

    }
    finally {
      setLoader(false)
    }
  }

  const seoData={
    "canonical": {
      "href": "https://gettingahead.education/terms"
    },
    "ogTitle": {
      "content": "Our Terms & Conditions | Getting Ahead"
    },
    "ogDescription": {
      "content": "Get familiar with our terms and conditions. We strive to offer a safe and smooth platform for employers and early career professionals."
    },
    "ogImage": {
      "content": "https://gettingahead.education/static/media/logo.f6ccefcc.svg",
      "type": "image/svg"
    }
  }
  

  return (
    <>

     <HelmetComponent {...seoData}/>
      <Grid className={`Terms ${props?.addClass}`}>
         {loader ? <Loader /> :<div dangerouslySetInnerHTML={{__html:description}}>
          </div>}
       
      </Grid>
      {props?.showAs === "page" && <Typography component="footer" className="footer">
       
      </Typography>}
    
    </>
  );
};
export default Terms;
